import React from 'react';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import { LS_ADDRESS } from '../../constants/localStorage';
import { useUrlParams } from '../../containers/UrlParamsProvider';
import AddressForm from './AddressForm';
import AddressResult from './AddressResult';

const MojHarmonogram = () => {
  const { ownerId } = useUrlParams();
  const [address, setAddress] = useLocalStorage(`${LS_ADDRESS}_${ownerId}`, null);

  if (address) {
    return <AddressResult address={address} onChangeAddress={() => setAddress(null)} />;
  }
  return (
    <AddressForm setAddress={setAddress} />
  );
};

MojHarmonogram.propTypes = {};

export default MojHarmonogram;
