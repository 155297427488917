import PropTypes from 'prop-types';
import React from 'react';
import chevronDown from '../assets/chevron-down.svg';
import chevronUp from '../assets/chevron-up.svg';
import color from '../constants/color';
import handleEnter from '../utils/handleEnter';
import { px } from '../utils/px';
import BlockImg from './generic/BlockImg';
import Flex from './generic/Flex';
import FlexItem from './generic/FlexItem';
import Text from './generic/Text';

const CollapseSwitch = ({ isOpen, closedText, openText, onToggle, withIcon, fontSize }) => (
  <Flex inline alignItems="center" tabIndex={0} onClick={onToggle} onKeyDown={handleEnter(onToggle)}>
    <FlexItem marginRight={withIcon ? px(8) : 0}>
      <Text color={color.link} fontSize={fontSize}>{isOpen ? openText : closedText}</Text>
    </FlexItem>
    {withIcon && <BlockImg src={isOpen ? chevronUp : chevronDown} alt={isOpen ? closedText : openText} width={px(9)} />}
  </Flex>
);

CollapseSwitch.propTypes = {
  isOpen: PropTypes.bool,
  closedText: PropTypes.string.isRequired,
  openText: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  withIcon: PropTypes.bool,
  fontSize: PropTypes.string,
};
export default CollapseSwitch;
