import { Formik } from 'formik';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useUrlParams } from '../../../../containers/UrlParamsProvider';
import { fetchBins, newsletter } from '../../../../utils/api';
import getCheckedValuesFromObject from '../../../../utils/getCheckedValuesFromObject';
import useListData, { nestedResponseResolver } from '../../../../utils/hooks/useListData';
import { initialValues, validationSchema } from './form';
import NewsletterForm from './NewsletterForm';

const Newsletter = ({ unitId }) => {
  const { ownerId } = useUrlParams();
  const [success, setSuccess] = useState(null);
  const [{ data: itemTypes }, fetchItemTypes] = useListData(newsletter.itemTypes, null);
  const [{ data: bins }, fetchWasteBins] = useListData(fetchBins, null, nestedResponseResolver);

  useEffect(() => {
    fetchItemTypes();
    fetchWasteBins(ownerId, unitId);
  }, [fetchWasteBins, fetchItemTypes, ownerId, unitId]);

  if (!itemTypes || !bins) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          setSuccess(null);
          // eslint-disable-next-line no-unused-vars
          const data = {
            ...values,
            binIds: getCheckedValuesFromObject(values.binIds).map(id => Number(id)),
            newsletterItemTypeCodes: getCheckedValuesFromObject(values.newsletterItemTypeCodes),
            unitId,
          };
          await newsletter.signup(ownerId, data);
          resetForm(initialValues);
          setSuccess(true);
        } catch (e) {
          console.error(e);
          setSuccess(false);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {formikProps => <NewsletterForm formikProps={formikProps} bins={bins} itemTypes={itemTypes} success={success} setSuccess={setSuccess} />}
    </Formik>
  );
};

Newsletter.propTypes = {
  unitId: PropTypes.string.isRequired,
};

export default Newsletter;
