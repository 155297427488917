import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import Flex from '../../components/generic/Flex';
import FlexItem from '../../components/generic/FlexItem';
import Text from '../../components/generic/Text';
import color from '../../constants/color';
import { defaultBorder, defaultBorderRadius } from '../../constants/style';
import { px } from '../../utils/px';

export const navItemMargin = px(2);

const NavBox = styled(FlexItem)`
  border: ${defaultBorder};
  ${props => props.active && css`
    border: ${px(1)} solid ${props.theme.colorAccent};
  `};
  border-radius: ${defaultBorderRadius};
  padding: ${px(12)} ${px(5)};
  height: ${px(130)};
  cursor: pointer;
  &:hover, &:focus {
    background-color: ${color.navBgHover};
  }
`;

const NavIcon = styled.div`
  fill: ${props => props.active ? props.theme.colorAccent : color.navItem};
`;

const NavTitle = styled(Text).attrs(props => ({
  textAlign: 'center',
  bold: props.active,
  color: props.active ? props.theme.colorAccent : color.navItem,
}))``;

const NavItem = ({ active, title, Icon, ...props }) => (
  <NavBox as="li" active={active} equal margin={navItemMargin} {...props}>
    <Flex column alignItems="center" height="100%">
      <NavIcon active={active}>
        <Icon style={{ width: px(65), height: px(65) }} />
      </NavIcon>
      <FlexItem equal>
        <Flex alignItems="center" height="100%">
          <NavTitle active={active}>
            {title}
          </NavTitle>
        </Flex>
      </FlexItem>
    </Flex>
  </NavBox>
);
NavItem.propTypes = {
  active: PropTypes.bool,
  title: PropTypes.string.isRequired,
  Icon: PropTypes.object.isRequired,
};

export default NavItem;
