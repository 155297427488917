import styled from 'styled-components';
import { PTspacing } from '../../constants/propTypes';
import { tableBorder } from '../../constants/style';
import { styleCssSpacing, su } from '../../utils/styledComponents';

const TableCell = styled.td`
  padding: ${su(3)} ${su(2)};
  border-bottom: ${tableBorder};
  border-right: ${tableBorder};
  vertical-align: top;
  
  ${styleCssSpacing('width')};
`;
TableCell.propTypes = {
  width: PTspacing,
};

export default TableCell;
