import styled from 'styled-components';
import { fontSize, fontWeight } from '../constants/font';
import { su } from '../utils/styledComponents';

const SectionHeader = styled.h2`
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.bold};
  margin-bottom: ${su(3)};
`;

export default SectionHeader;
