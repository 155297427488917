import Downshift from 'downshift';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ArrowIcon from '../autocomplete/ArrowIcon';
import ControllerButton from '../autocomplete/ControllerButton';
import Item from '../autocomplete/Item';
import ItemsList from '../autocomplete/ItemsList';
import Block from '../generic/Block';
import FormInput from './FormInput';

const defaultItemToString = item => item ? item.label : '';
const defaultItemToValue = item => item ? item.value : null;

const Select = ({
  id,
  name,
  items,
  value,
  onChange,
  itemToLabel = defaultItemToString,
  itemToValue = defaultItemToValue,
  ariaLabel,
  inputProps,
}) => {
  const selectedItem = items.find(item => itemToValue(item) === value) || items[0];
  const inputRef = useRef();

  const stateReducer = (state, changes) => {
    // eslint-disable-next-line no-prototype-builtins
    if (changes.hasOwnProperty('isOpen') && !changes.isOpen) {
      inputRef.current.blur();
    }

    return changes;
  };

  return (
    <Downshift
      inputId={id}
      onChange={selected => onChange(itemToValue(selected))}
      itemToString={itemToLabel}
      selectedItem={selectedItem}
      stateReducer={stateReducer}
    >
      {({
        getRootProps,
        getInputProps,
        getToggleButtonProps,
        getMenuProps,
        getItemProps,
        isOpen,
        toggleMenu,
        highlightedIndex,
      }) => (
        <Block relative {...getRootProps()}>
          <Block relative>
            <FormInput
              {...getInputProps({
                readOnly: true,
                ref: inputRef,
                name,
                onClick: isOpen ? undefined : toggleMenu,
                active: isOpen,
                spellcheck: false,
                ...inputProps,
              })}
            />
            <ControllerButton {...getToggleButtonProps()}>
              <ArrowIcon style={isOpen ? { transform: 'rotate(180deg)' } : undefined} />
            </ControllerButton>
          </Block>
          <Block relative>
            <ItemsList {...getMenuProps({ isOpen, 'aria-label': ariaLabel })}>
              {isOpen
                ? items
                  .map((item, index) => (
                    <Item
                      key={itemToValue(item)}
                      {...getItemProps({
                        item,
                        index,
                        highlighted: highlightedIndex === index,
                        selected: selectedItem === item,
                        fontSize: inputProps ? inputProps.fontSize : undefined,
                      })}
                    >
                      {itemToLabel(item)}
                    </Item>
                  ))
                : null}
            </ItemsList>
          </Block>
        </Block>
      )}
    </Downshift>
  );
};

Select.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  itemToLabel: PropTypes.func,
  itemToValue: PropTypes.func,
  ariaLabel: PropTypes.string,
  inputProps: PropTypes.object,
};

export default Select;
