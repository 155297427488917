import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import FormCheckbox from './FormCheckbox';

const FormikCheckbox = ({ label, name, ...props }) => (
  <Field name={name}>
    {({ field: { value, ...restField } }) => {
      return (
        <FormCheckbox
          label={label}
          {...restField}
          checked={value}
          {...props}
        />
      );
    }}
  </Field>
);

FormikCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  ...FormCheckbox.propTypes,
};

export default FormikCheckbox;
