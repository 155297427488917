import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { defaultLineHeight } from '../../constants/font';
import { PTchildren } from '../../constants/propTypes';
import { px } from '../../utils/px';
import { su } from '../../utils/styledComponents';
import Flex from '../generic/Flex';

const Circle = styled.div`
  width: ${px(10)};
  height: ${px(10)};
  border-radius: 50%;
  background-color: ${props => props.color};
  margin-right: ${su(2)};
`;

const TableListItem = ({ color, children }) => (
  <Flex as="li" marginBottom={1} alignItems="flex-start">
    <Flex height={px(defaultLineHeight)} alignItems="center">
      <Circle color={color} />
    </Flex>
    {children}
  </Flex>
);

TableListItem.propTypes = {
  color: PropTypes.string.isRequired,
  children: PTchildren,
};

export default TableListItem;
