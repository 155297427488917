import React, { useContext, useMemo, useState } from 'react';
import { PTchildren } from '../constants/propTypes';
import { useUrlParams } from './UrlParamsProvider';

const NavigationContext = React.createContext();

export const NavigationProvider = ({ children }) => {
  const { initialView } = useUrlParams();
  const [activeView, goToView] = useState(initialView);

  const value = useMemo(() => ({ activeView, goToView }), [activeView]);
  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};

NavigationProvider.propTypes = {
  children: PTchildren,
};

export const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (context === undefined) {
    throw new Error('useNavigation must be used  together with NavigationProvider');
  }
  return context;
};
