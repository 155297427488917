import PropTypes from 'prop-types';
import React from 'react';
import Divider from '../../../components/Divider';
import Block from '../../../components/generic/Block';
import Flex from '../../../components/generic/Flex';
import FlexItem from '../../../components/generic/FlexItem';
import Text from '../../../components/generic/Text';
import Link from '../../../components/Link';
import Panel from '../../../components/Panel';
import PokazNaMapie from '../../../components/PokazNaMapie';
import Table from '../../../components/table/Table';
import TableBody from '../../../components/table/TableBody';
import TableCell from '../../../components/table/TableCell';
import TableHeader from '../../../components/table/TableHeader';
import TableListItem from '../../../components/table/TableListItem';
import color from '../../../constants/color';
import WasteTypeId from '../../../constants/enums/WasteTypeId';

const PunktSelektywnejZbiorki = ({ pszoks, segregation }) => (
  <Panel>
    <ul>
      {pszoks.map(pszok => (
        <Block as="li" marginBottom={4} key={pszok.id}>
          <Text bold>{pszok.name}</Text>
          <Divider marginBottom={3} />
          <Flex paddingH={3}>
            <FlexItem equal>
              <Block marginBottom={3}>
                <Text bold marginBottom={1}>Adres:</Text>
                <Text>{pszok.location}</Text>
                {pszok.phone && <Text>tel.: {pszok.phone}</Text>}
              </Block>
              {(pszok.email || pszok.webPage) && (
                <Block>
                  {pszok.email && <Link external href={`mailto:${pszok.email}`}>{pszok.email}</Link>}
                  {pszok.webPage && <Link external href={pszok.webPage}>{pszok.webPage}</Link>}
                </Block>
              )}
            </FlexItem>
            {pszok.openingHours && (
              <FlexItem equal>
                <Text bold marginBottom={1}>Godziny otwarcia:</Text>
                <div style={{ whiteSpace: 'pre-wrap' }}>{pszok.openingHours}</div>
              </FlexItem>
            )}
          </Flex>
        </Block>
      ))}
    </ul>
    {pszoks.length > 0 && (
      <Block marginBottom={4}>
        <PokazNaMapie wasteTypes={[WasteTypeId.pszok]} />
      </Block>
    )}
    {segregation && (
      <Table>
        <thead>
          <tr>
            <TableHeader width="50%">Przyjmowane odpady</TableHeader>
            <TableHeader width="50%">Nieprzyjmowane odpady</TableHeader>
          </tr>
        </thead>
        <TableBody>
          <tr>
            <TableCell width="50%">
              <ul>
                {segregation.allowed.map(allowedEntry => (
                  <TableListItem key={allowedEntry} color={color.throwAllowed}>{allowedEntry}</TableListItem>
                ))}
              </ul>
            </TableCell>
            <TableCell width="50%">
              <ul>
                {segregation.disallowed.map(disallowedEntry => (
                  <TableListItem key={disallowedEntry} color={color.throwDisallowed}>{disallowedEntry}</TableListItem>
                ))}
              </ul>
            </TableCell>
          </tr>
        </TableBody>
      </Table>
    )}
  </Panel>
);
PunktSelektywnejZbiorki.propTypes = {
  pszoks: PropTypes.arrayOf(PropTypes.object).isRequired,
  segregation: PropTypes.shape({
    allowed: PropTypes.arrayOf(PropTypes.string),
    disallowed: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default PunktSelektywnejZbiorki;
