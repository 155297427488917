import styled from 'styled-components';
import { MEDIA_CSS } from '../constants/breakpoints';
import color from '../constants/color';
import { styleCssSpacing, su } from '../utils/styledComponents';
import Block from './generic/Block';

const Panel = styled(Block)`
  background-color: ${color.panelBg};
  padding: ${su(2)};
  @media (${MEDIA_CSS.tablet}) {
    padding: ${su(3)};
  }
  ${styleCssSpacing('padding')};
`;
Panel.propTypes = Block.propTypes;
export default Panel;
