import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import starFilled from '../../../../assets/star-filled.svg';
import star from '../../../../assets/star.svg';
import Button from '../../../../components/Button';
import Block from '../../../../components/generic/Block';
import BlockImg from '../../../../components/generic/BlockImg';
import Flex from '../../../../components/generic/Flex';
import FlexItem from '../../../../components/generic/FlexItem';
import Text from '../../../../components/generic/Text';
import color from '../../../../constants/color';
import handleEnter from '../../../../utils/handleEnter';

const Stars = ({ company, rate }) => {
  const [success, setSuccess] = useState(null);
  const [hoveredRating, setHoveredRating] = useState(null);
  const [selectedRating, setSelectedRating] = useState(null);

  const ratingToShow = company.rating || selectedRating;

  const handleSubmit = useCallback(async () => {
    try {
      await rate(selectedRating);
      setSuccess(true);
    } catch (e) {
      setSuccess(false);
    }
  }, [rate, selectedRating]);
  return (
    <>
      <Text marginBottom={3}>Oceń firmę wywożącą:</Text>
      <Flex marginBottom={3} marginH={-1}>
        {[1, 2, 3, 4, 5].map((starRating) => {
          const isHighlighted = (company.rating && company.rating >= starRating)
            || (selectedRating && selectedRating >= starRating)
            || (hoveredRating && hoveredRating >= starRating);
          const handleStarClick = () => setSelectedRating(starRating);
          return (
            <Block
              key={starRating}
              paddingH={1}
              onMouseEnter={company.rating ? undefined : () => setHoveredRating(starRating)}
              onMouseLeave={company.rating ? undefined : () => setHoveredRating(null)}
              onFocus={company.rating ? undefined : () => setHoveredRating(starRating)}
              onBlur={company.rating ? undefined : () => setHoveredRating(null)}
              tabIndex={company.rating ? undefined : '0'}
              onClick={company.rating ? undefined : handleStarClick}
              onKeyDown={company.rating ? undefined : handleEnter(handleStarClick)}
            >
              <BlockImg
                width={4.4}
                height={4.2}
                src={isHighlighted ? starFilled : star}
                alt={isHighlighted ? `wypełniona gwiazdka ${starRating}/5` : `pusta gwiazdka ${starRating}/5`}
              />
            </Block>
          );
        })}
      </Flex>
      <Flex>
        <FlexItem marginRight={3}>
          <Text>Twoja ocena: {ratingToShow ? `${ratingToShow} / 5` : 'brak'}</Text>
          {(typeof success === 'boolean' && success) && <Text color={color.formSent}>Dziękujemy, Twoja ocena została dodana.</Text>}
          {(typeof success === 'boolean' && !success) && <Text color={color.validationError}>Wystąpił problem z przesłaniem oceny</Text>}
          <Text>Opinii: {company.opinionsCount}</Text>
        </FlexItem>
        {!company.rating && selectedRating && (
          <Button
            small
            onClick={handleSubmit}
          >
            Zatwierdź
          </Button>
        )}
      </Flex>
    </>
  );
};

Stars.propTypes = {
  company: PropTypes.object.isRequired,
  rate: PropTypes.func.isRequired,
};

export default Stars;
