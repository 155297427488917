import { pxMediaQueryEM } from '../utils/pxMediaQueryEM';

export const BREAKPONTS = {
  tablet: pxMediaQueryEM(640),
  desktop: pxMediaQueryEM(1140),
};

export const BREAKPOINTS_ARRAY = [
  BREAKPONTS.tablet,
  BREAKPONTS.desktop,
];

export const MEDIA = {
  tablet: `(min-width: ${BREAKPONTS.tablet})`,
  desktop: `(min-width: ${BREAKPONTS.desktop})`,
};

export const MEDIA_CSS = {
  tablet: `min-width: ${BREAKPONTS.tablet}`,
  desktop: `min-width: ${BREAKPONTS.desktop}`,
};

export const DEVICE = {
  mobile: 0,
  tablet: 1,
  desktop: 2,
};
