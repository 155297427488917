import PropTypes from 'prop-types';
import styled from 'styled-components';
import color from '../constants/color';
import { fontSize } from '../constants/font';
import Text from './generic/Text';

const Link = styled(Text.withComponent('a')).attrs(props => ({
  ...(props.external ? {
    rel: 'noopener',
    target: '_blank',
  } : {}),
}))`
  &:hover {
    text-decoration: underline;
  }
`;
Link.defaultProps = {
  color: color.link,
  fontSize: fontSize.default,
};
Link.propTypes = {
  inline: PropTypes.bool,
  href: PropTypes.string.isRequired,
  external: PropTypes.bool,
  fontSize: PropTypes.string,
};

export default Link;
