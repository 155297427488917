import axios from 'axios';
import { mapboxAccessToken } from '../config';
import { ReportType } from '../views/Zglos/content';

const getEnvironment = () => {
  if (!process.env.REACT_APP_API_ENV) {
    return 'development';
  }

  return process.env.REACT_APP_API_ENV.trim().toLowerCase();
};

export const ENVIRONMENT = getEnvironment();

const getBackendEnvURL = () => {
  switch (ENVIRONMENT) {
    case 'test1':
      return 'https://gw1.test.sisms.pl/akun';
    case 'test2':
    case 'development':
      return 'https://gw2.test.sisms.pl/akun';
    case 'test3':
      return 'https://gw3.test.sisms.pl/akun';
    case 'prod':
    default:
      return 'https://gateway.sisms.pl/akun';
  }
};

export const backendEnvURL = getBackendEnvURL();
const baseURL = `${backendEnvURL}/api`;
const client = axios.create({
  baseURL,
});

export const owner = {
  info: ownerId => client.get(`/owners/${ownerId}/info`),
  regulations: ownerId => client.get(`/owners/${ownerId}/regulations`),
  wasteAll: ownerId => client.get(`/owners/${ownerId}/waste-all`),
};

export const unit = {
  towns: ownerId => client.get(`/owners/${ownerId}/towns/list`),
  streets: (ownerId, townId) => client.get(`/owners/${ownerId}/streets/list`, { params: { townId } }),
  streetAddresses: (ownerId, streetId) => client.get(`/owners/${ownerId}/streetAddresses/list`, { params: { streetId } }),
  townAddresses: (ownerId, townId) => client.get(`/owners/${ownerId}/townAddresses/list`, { params: { townId } }),
};

export const companies = {
  list: (ownerId, unitId) => client.get(`/owners/${ownerId}/companies/list`, { params: { unitId } }),
  rate: (ownerId, companyId, myRating) => client.post(
    `/owners/${ownerId}/companies/rate`,
    null,
    { params: { companyId, myRating } },
  ),
};

export const wasteSearchEngine = {
  prompt: (ownerId, phrase) => client.get(`/owners/${ownerId}/waste-search-engine/prompt`, { params: { phrase } }),
  search: (ownerId, phrase) => client.get(`/owners/${ownerId}/waste-search-engine`, { params: { phrase } }),
};

export const fetchTimetable = (ownerId, unitId) => client.get(`/owners/${ownerId}/timetable/get`, { params: { unitId } });
export const fetchTimetablePdf = (ownerId, unitId, command) => {
  axios({
    baseURL,
    url: `/owners/${ownerId}/timetable`,
    method: 'PATCH',
    data: { command: command, unitId: unitId },
    responseType: 'blob',
    headers: {
      Accept: 'application/pdf',
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Harmonogram.pdf');
    document.body.appendChild(link);
    link.click();
  });
};
export const fetchBins = (ownerId, unitId) => client.get(`/owners/${ownerId}/bins/list`, { params: { unitId } });
export const fetchPaymentPeriods = (ownerId, unitId) => client.get(`/owners/${ownerId}/payment-period`, { params: { unitId } });

export const newsletter = {
  signup: (ownerId, data) => client.post(`/newsletter/${ownerId}`, data),
  itemTypes: () => client.get('/newsletter/item-type'),
};

export const fetchWasteSegregation = ownerId => client.get(`/owners/${ownerId}/waste-segregation `);
export const fetchWasteCollectionPoints = ownerId => client.get(`/owners/${ownerId}/waste-collection-points/search?type=pszok`);
export const fetchMapPoints = ownerId => client.get(`/owners/${ownerId}/map-points`);

export const fetchTips = ownerId => client.get(`/owners/${ownerId}/tips`);
export const fetchGuides = ownerId => client.get(`/owners/${ownerId}/guides`);

export const fetchCommunalDocuments = ownerId => client.get(`/owners/${ownerId}/owner-communal-documents`);

export const report = {
  getTypes: ownerId => client.get(`/owners/${ownerId}/report/types`),
  report: (ownerId, type, attachments, reportData) => {
    if ([ReportType.WILD_DUMP, ReportType.SMOKE, ReportType.OTHER].includes(type)) {
      // https://stackoverflow.com/questions/50774176/sending-file-and-json-in-post-multipart-form-data-request-with-axios
      const reportJSON = JSON.stringify(reportData);
      const reportBlob = new Blob([reportJSON], {
        type: 'application/json',
      });
      const formData = new FormData();
      formData.append('report', reportBlob);
      attachments.forEach((file) => {
        formData.append('attachments', file);
      });
      return client.post(`/owners/${ownerId}/report/${type}`, formData, { headers: { 'content-type': 'multipart/form-data' } });
    }
    return client.post(`/owners/${ownerId}/report/${type}`, reportData);
  }
};

export const mapboxGeoCoding = search => axios.get(
  `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(search)}.json`,
  {
    params: {
      access_token: mapboxAccessToken,
      country: 'PL',
      language: 'pl',
      types: 'address,poi',
    },
  });

export const mapboxGeoCodingReverse = ({ longitude, latitude }) => axios.get(
  `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json`,
  {
    params: {
      access_token: mapboxAccessToken,
      country: 'PL',
      language: 'pl',
      types: 'address,poi',
    },
  });
