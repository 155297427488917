import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import color from '../../constants/color';
import { fontSize, fontWeight } from '../../constants/font';
import { PTresponsive } from '../../constants/propTypes';
import handleEnter from '../../utils/handleEnter';
import { styleCss } from '../../utils/styledComponents';
import { spaceProps, spacePropTypes } from './Block';

const Text = styled.span.attrs(props => ({
  onKeyDown: props.onClick ? handleEnter(props.onClick) : undefined,
  tabIndex: props.onClick ? '0' : undefined,
}))`
  display: block;
  ${props => props.inline && css`
    display: inline-block;
  `};
  ${spaceProps};
  ${styleCss('color')};
  ${styleCss('fontFamily')};
  ${styleCss('fontSize')};
  ${styleCss('fontWeight')};
  ${styleCss('textAlign')};
  ${styleCss('lineHeight')};
  
  
  ${props => props.bold && css`
    font-weight: ${fontWeight.bold};
  `};
`;
Text.defaultProps = {
  color: color.fontDefault,
  fontSize: fontSize.default,
};
Text.propTypes = {
  ...spacePropTypes,
  inline: PropTypes.bool,
  fontFamily: PropTypes.string,
  fontSize: PTresponsive(PropTypes.string),
  fontWeight: PTresponsive(PropTypes.number),
  bold: PropTypes.bool,
  textAlign: PTresponsive(PropTypes.oneOf(['center', 'start', 'end'])),
  lineHeight: PropTypes.string,
  color: PropTypes.string,
};
export default Text;
