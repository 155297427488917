import React, { useCallback, useEffect, useState } from "react";
import { px } from "../../utils/px";
import styled, { css } from "styled-components";
import { defaultBorderRadius } from "../../constants/style";
import color from "../../constants/color";
import { useNavigation } from "../../containers/NavigationProvider";
import { ReactComponent as Wiecej } from "../../assets/navigation/wiecej.svg";
import handleEnter from "../../utils/handleEnter";
import NavItem from "./NavItem";

const NavDropDown = styled.div`
  position: relative;
  z-index: 1;
`

const NavDropDownContent = styled.div`
  ${ props => !props.active && css`
    display: none;
  ` }
  position: absolute;
  top: ${ px(140) };
  right: ${ px(10) };
  border: ${ px(1) } solid ${ props => props.theme.colorAccent };
  border-radius: ${ defaultBorderRadius };
  background-color: #ffffff;
`

const NavDropDownItem = styled.p`
  margin: ${ px(0) } ${ px(2) };
  width: ${ px(260) };
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover, &:focus {
    background-color: ${ color.navBgHover };
  }
`

const NavDropDownMenu = ({ items }) => {
  const { activeView, goToView } = useNavigation();
  const [ dropdownOpen, setDropdownOpen ] = useState(false);

  const onTriggerClick = useCallback(() => {
    setDropdownOpen(true);
  }, []);

  const onDropdownClose = useCallback(() => {
    setDropdownOpen(false)
  }, []);

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener("click", onDropdownClose);
      document.addEventListener("keypress", onDropdownClose);
    } else {
      document.removeEventListener("click", onDropdownClose);
      document.removeEventListener("keypress", onDropdownClose);
    }
  }, [ dropdownOpen, onDropdownClose ])

  return (
    <>
      <NavItem
        title={ "Więcej" }
        Icon={ Wiecej }
        active={ items.map(item => item.view).includes(activeView) }
        onClick={ onTriggerClick }
        onKeyDown={ handleEnter(onTriggerClick) }
        tabIndex="0"
      />
      <NavDropDown>
        <NavDropDownContent active={ dropdownOpen }>
          { items.map(item => {
            return (
              <NavDropDownItem
                key={ item.view }
                onClick={ () => goToView(item.view) }
                onKeyDown={ () => handleEnter(goToView(item.view)) }
                tabIndex="0">
                <item.Icon style={ { width: px(35), height: px(35), paddingRight: px(4) } }/>
                { item.title }
              </NavDropDownItem>
            )
          }) }
        </NavDropDownContent>
      </NavDropDown>
    </>
  )
}

export default NavDropDownMenu;