import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { defaultBorder } from '../constants/style';
import { px } from '../utils/px';

const RoundButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${px(34)};
  height: ${px(34)};
  ${props => props.withBorder && css`
    border: ${defaultBorder};
  `};
  border-radius: 50%;
  background-color: #ffffff;
  color: ${props => props.theme.colorAccent};
  ${props => props.onClick && css`
    cursor: pointer;
  `};
  
  transition: background-color 250ms ease-in-out, color 250ms ease-in-out;
  ${props => (props.invertOnHover && !props.disabled) && css`
    &:hover {
      background-color: ${props.theme.colorAccent};
      color: #ffffff;
    }
  `};
  ${props => props.disabled && css`
     opacity: 0.5;
  `};
`;

const RoundArrowButton = ({ icon: Icon, ...props }) => (
  <RoundButton {...props}>
    <Icon style={{ width: px(10), height: px(10) }} />
  </RoundButton>
);

RoundArrowButton.propTypes = {
  icon: PropTypes.object.isRequired,
  withBorder: PropTypes.bool,
  invertOnHover: PropTypes.bool,
};

export default RoundArrowButton;
