import styled from 'styled-components';
import { su } from '../../utils/styledComponents';

const ControllerButton = styled.button`
  outline: none;
  background-color: transparent;
  border: none;
  position: absolute;
  right: ${su(3)};
  top: 0;
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export default ControllerButton;
