import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ReactComponent as Pin } from '../assets/map-pin.svg';
import color from '../constants/color';
import { View } from '../constants/enums/View';
import { useMapFilters } from '../containers/MapFiltersProvider';
import { useNavigation } from '../containers/NavigationProvider';
import { useOwnerInfo } from '../containers/OwnerInfoProvider';
import { useUrlParams } from '../containers/UrlParamsProvider';
import { getWasteBinsToShow } from '../utils/getWasteBinsToShow';
import handleEnter from '../utils/handleEnter';
import { px } from '../utils/px';
import Flex from './generic/Flex';
import FlexItem from './generic/FlexItem';
import Text from './generic/Text';

const MapPin = styled(Pin)`
  color: ${color.link};
`;

const PokazNaMapie = ({ wasteTypes }) => {
  const { hideMenu } = useUrlParams();
  const { goToView } = useNavigation();
  const { setMapFilters } = useMapFilters();
  const { wasteBins, mapPoints } = useOwnerInfo();

  const wasteBinsCodesToShowSet = useMemo(
    () => new Set(getWasteBinsToShow(mapPoints, wasteBins).map(bin => bin.code)),
    [mapPoints, wasteBins]
  );
  const wasteTypesToShow = wasteTypes
    .filter(wasteTypeCode => wasteBinsCodesToShowSet.has(wasteTypeCode));

  if (hideMenu || wasteTypesToShow.length === 0) {
    return null;
  }

  const goToMap = () => {
    setMapFilters(wasteTypesToShow);
    goToView(View.znajdzNaMapie);
  };
  return (
    <Flex
      inline
      alignItems="center"
      tabIndex="0"
      onClick={goToMap}
      onKeyDown={handleEnter(goToMap)}
    >
      <FlexItem shrink={0} width={px(14)} height={px(17)} marginRight={1}>
        <MapPin />
      </FlexItem>
      <FlexItem equal>
        <Text color={color.link}>Pokaż na mapie</Text>
      </FlexItem>
    </Flex>
  );
};

PokazNaMapie.propTypes = {
  wasteTypes: PropTypes.arrayOf(PropTypes.string),
};
export default PokazNaMapie;
