import styled from 'styled-components';
import color from '../../constants/color';
import { px } from '../../utils/px';

const Info = styled.div`
  padding: ${px(5)} ${px(14)};
  text-align: center;
  color: ${color.inputPlaceholder}
`;

export default Info;
