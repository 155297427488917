import PropTypes from 'prop-types';
import React from 'react';
import Grid from '../../../components/generic/Grid';
import Panel from '../../../components/Panel';
import PokazNaMapie from '../../../components/PokazNaMapie';
import Table from '../../../components/table/Table';
import TableBody from '../../../components/table/TableBody';
import TableCell from '../../../components/table/TableCell';
import TableHeader from '../../../components/table/TableHeader';
import TableListItem from '../../../components/table/TableListItem';
import { DEVICE } from '../../../constants/breakpoints';
import color from '../../../constants/color';
import WasteTypeId from '../../../constants/enums/WasteTypeId';
import useDevice from '../../../utils/hooks/useDevice';
import { useOwnerInfo } from '../../../containers/OwnerInfoProvider';


const OdpadyWielkogabarytowe = ({ segregation }) => {
  const device = useDevice();
  const { mapPoints } = useOwnerInfo();

  const checkVisibilityShowInMap = () => {
    for (let mapPoint of mapPoints) {
      if (mapPoint.types.includes('gabaryt')) {
        return true;
      }
    }
    return false;
  }

  return (
    <Panel>
      <Grid gap={2} flow={device === DEVICE.mobile ? 'row' : 'column'} marginBottom={2} justifyContent="space-between">
        {
          checkVisibilityShowInMap() &&
          <div>
            Zobacz na mapie punkty zbiórki w Twojej okolicy
          </div>
        }
        <PokazNaMapie wasteTypes={[WasteTypeId.gabaryt]} />
      </Grid>
      {segregation && (
        <Table>
          <thead>
            <tr>
              <TableHeader width="50%">Co wrzucać?</TableHeader>
              <TableHeader width="50%">Czego nie wrzucać?</TableHeader>
            </tr>
          </thead>
          <TableBody>
            <tr>
              <TableCell width="50%">
                <ul>
                  {segregation.allowed.map(allowedEntry => (
                    <TableListItem key={allowedEntry} color={color.throwAllowed}>{allowedEntry}</TableListItem>
                  ))}
                </ul>
              </TableCell>
              <TableCell width="50%">
                <ul>
                  {segregation.disallowed.map(disallowedEntry => (
                    <TableListItem key={disallowedEntry} color={color.throwDisallowed}>{disallowedEntry}</TableListItem>
                  ))}
                </ul>
              </TableCell>
            </tr>
          </TableBody>
        </Table>
      )}
    </Panel>
  );
};

OdpadyWielkogabarytowe.propTypes = {
  segregation: PropTypes.shape({
    allowed: PropTypes.arrayOf(PropTypes.string),
    disallowed: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default OdpadyWielkogabarytowe;
