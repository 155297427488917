/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PageHeader from '../../components/PageHeader';
import { useUrlParams } from '../../containers/UrlParamsProvider';
import { fetchBins, fetchGuides as fetchGuidesApiCall, fetchTips as fetchTipsApiCall, fetchWasteCollectionPoints, fetchWasteSegregation } from '../../utils/api';
import useData from '../../utils/hooks/useData';
import useListData, { nestedResponseResolver } from '../../utils/hooks/useListData';
import WartoWiedziecItem from './components/WartoWiedziecItem';
import itemContent from "./itemContent";
import Spinner from "../../components/Spinner";
import Flex from "../../components/generic/Flex";

const WartoWiedziec = () => {
  const { ownerId } = useUrlParams();

  const [{ data: pszoks }, fetchPszoks] = useListData(fetchWasteCollectionPoints, []);
  const [{ data: tips }, fetchTips] = useListData(fetchTipsApiCall, []);
  const [{ data: guides, pending: pendingGuides }, fetchGuides] = useListData(fetchGuidesApiCall, []);
  const [{ data: segregations }, fetchSegregations] = useListData(fetchWasteSegregation, []);
  const { data: bins, fetch: fetchWasteBins } = useData(fetchBins, null, nestedResponseResolver);

  useEffect(() => {
    if (ownerId) {
      fetchPszoks(ownerId);
      fetchSegregations(ownerId);
      fetchWasteBins(ownerId, '');
      fetchGuides(ownerId);
      fetchTips(ownerId);
    } else {
      console.error('Unable to fetch data (WartoWiedziec). No ownerId provided in URL params');
    }
  }, [ownerId, fetchPszoks, fetchSegregations, fetchWasteBins, fetchTips, fetchGuides]);

  if (pendingGuides) {
    return (
      <Flex justifyContent="center">
        <Spinner width={12} />
      </Flex>
    );
  }

  return (
    <div>
      <PageHeader>Kompendium wiedzy na temat odpadów</PageHeader>
      <ul>
        {guides
            .map(({code, subject, description}) => itemContent(code, subject, description))
            .filter(item => item)
            .map(({ title, Icon, description, renderContent }) => (
          <WartoWiedziecItem
            as="li"
            key={title}
            Icon={Icon}
            title={title}
            description={description}
            MoreContent={renderContent({ pszoks, segregations, bins, tips })}
          />
        ))}
      </ul>
    </div>
  );
};

WartoWiedziec.propTypes = {};

export default WartoWiedziec;
