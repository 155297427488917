import React, { useEffect } from "react";
import Panel from "../../components/Panel";
import { su } from "../../utils/styledComponents";
import { px } from "../../utils/px";
import PageHeader from "../../components/PageHeader";
import AdministrationNoticeAddress from "../MojHarmonogram/components/AdministrationNoticeAddress";
import Grid from "../../components/generic/Grid";
import Link from "../../components/Link";
import { backendEnvURL, fetchCommunalDocuments as fetchCommunalDocumentsApiCall } from "../../utils/api";
import { ReactComponent as Download } from '../../assets/icon_download.svg';
import Text from "../../components/generic/Text";
import { useUrlParams } from "../../containers/UrlParamsProvider";
import useListData from "../../utils/hooks/useListData";

const DokumentyDlaMieszkanca = () => {
  const { ownerId } = useUrlParams();
  const [ { data: communalDocuments }, fetchCommunalDocuments ] = useListData(fetchCommunalDocumentsApiCall, []);

  useEffect(() => {
    if (ownerId) {
      fetchCommunalDocuments(ownerId)
    } else {
      console.error('Unable to fetch data (DokumentyDlaMieszkanca). No ownerId provided in URL params');
    }
  }, [ fetchCommunalDocuments, ownerId ]);

  return (
    <div>
      <PageHeader>Dokumenty do pobrania</PageHeader>
      <Panel padding={ [ 4, `${ su(6) } ${ su(10) } ${ su(6) } ${ su(3) }` ] } marginBottom={ px(32) }>
        {
          communalDocuments?.data &&
          communalDocuments.data.map(communalDocument => {
            return (
              <Grid columnGap={ 2 } flow={ "column" } columns={ "24px minmax(0, max-content)" } marginBottom={ `${px(6)}` } alignItems={ "center" } key={ communalDocument.id }>
                <Download/>
                <Link external href={ `${ backendEnvURL }/api/owners/${ ownerId }/owner-communal-documents/${ communalDocument.id }` }>
                  { communalDocument.filename }
                </Link>
                <Text>{ formatFileSize(communalDocument.fileSize) }</Text>
              </Grid>
            )
          })
        }
      </Panel>
      <AdministrationNoticeAddress marginBottom={ 2 }/>
    </div>
  )
}

const formatFileSize = (sizeInBytes) => {
  if (sizeInBytes < 1024) {
    return `${ sizeInBytes } B`;
  }

  if (sizeInBytes < 1024 * 1024) {
    return `${ (sizeInBytes / 1024).toFixed(2) } KB`;
  }

  return `${ (sizeInBytes / 1024 / 1024).toFixed(2) } MB`;
}

export default DokumentyDlaMieszkanca;