import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { PTspacing } from '../../constants/propTypes';
import { styleCss, styleCssSpacing } from '../../utils/styledComponents';
import Block from './Block';

const FlexItem = styled(Block)`
  // this rule is first to conviniently override
  ${props => props.equal && css`
    flex-basis: 0;
    min-width: 0;
    min-height: 0;
    flex-grow: ${typeof props.equal === 'number' ? props.equal : 1};
  `};
  ${props => ((props.grow || props.grow === 0) && `flex-grow: ${props.grow};`)}
  ${props => ((props.shrink || props.shrink === 0) && `flex-shrink: ${props.shrink};`)}
  ${styleCssSpacing('basis', 'flex-basis')};
  ${styleCss('alignSelf')};
  ${styleCss('order')};
  
`;

FlexItem.propTypes = {
  ...Block.propTypes,
  grow: PropTypes.number,
  shrink: PropTypes.number,
  basis: PTspacing,
  alignSelf: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'baseline', 'stretch']),
  order: PropTypes.number,
  equal: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

export default FlexItem;
