import * as PropTypes from 'prop-types';
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';
import color from '../constants/color';
import { defaultFontSizePercent, defaultLineHeightRatio, fontFamily, fontWeight } from '../constants/font';
import { useZoom } from '../containers/ZoomProvider';

const GlobalStylesComponent = createGlobalStyle`
  ${styledNormalize};
  html {
    height: 100%;
    box-sizing: border-box;
    font-size: ${props => defaultFontSizePercent + props.zoomLevel * 10}%;
  }
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: ${fontFamily};
    font-size: 1rem;
    font-weight: ${fontWeight.default};
    line-height: ${defaultLineHeightRatio};
    background-color: ${color.pageBackground};
    color: ${color.fontDefault};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  };
  * {
    &,
    &:before,
    &:after {
      box-sizing: inherit;
    }
  }
  
  a {
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }
  
  p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
  
  ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
  }
  
  button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
  }
`;
GlobalStylesComponent.propTypes = {
  zoomLevel: PropTypes.number.isRequired,
};

const GlobalStyles = () => {
  const { zoomLevel } = useZoom();
  return <GlobalStylesComponent zoomLevel={zoomLevel} />;
};

export default GlobalStyles;
