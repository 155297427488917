import round from '../utils/round';

export const fontFamily = '"Noto Sans", sans-serif';
export const fontFamilyAlternative = '"Ubuntu", sans-serif';

export const defaultFontSize = 14;
export const defaultLineHeight = 19;
export const defaultLineHeightRatio = round(defaultLineHeight / defaultFontSize, 2);
export const defaultFontSizePercent = round(100 * defaultFontSize / 16, 1); // 14/16 = 87,5%

export const fontSize = {
  small: pxToRem(12),
  semiSmall: pxToRem(13),
  default: pxToRem(defaultFontSize),
  semiLarge: pxToRem(16),
  large: pxToRem(18),
  extraLargeMobile: pxToRem(20),
  extraLarge: pxToRem(24),
};

export const fontWeight = {
  default: 400,
  medium: 500,
  bold: 700,
};

function pxToRem(px) {
  return `${round(px / defaultFontSize, 3)}rem`;
}
