import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import search from '../../../assets/search-question.svg';
import Block from '../../../components/generic/Block';
import BlockImg from '../../../components/generic/BlockImg';
import Flex from '../../../components/generic/Flex';
import FlexItem from '../../../components/generic/FlexItem';
import Text from '../../../components/generic/Text';
import PokazNaMapie from '../../../components/PokazNaMapie';
import { DEVICE } from '../../../constants/breakpoints';
import WasteTypeId from '../../../constants/enums/WasteTypeId';
import { defaultBorder } from '../../../constants/style';
import { useOwnerInfo } from '../../../containers/OwnerInfoProvider';
import { useUrlParams } from '../../../containers/UrlParamsProvider';
import { fetchWasteCollectionPoints } from '../../../utils/api';
import useDevice from '../../../utils/hooks/useDevice';
import useListData from '../../../utils/hooks/useListData';
import Call from './Call';

const StyledFlex = styled(Flex)`
  &:last-of-type {
    margin-bottom: 0
  }
`;

const SearchResults = ({ searchResults, searchPhrase }) => {
  const device = useDevice();
  const { ownerId } = useUrlParams();
  const { wasteBins } = useOwnerInfo();
  const [{ data }, fetch] = useListData(fetchWasteCollectionPoints, []);

  useEffect(() => {
    fetch(ownerId);
  }, [fetch, ownerId]);

  const pszokPhone = useMemo(() => {
    const pszoksWithPhone = data.filter(pszok => pszok.phone);
    return pszoksWithPhone.length > 0 ? pszoksWithPhone[0].phone : null;
  }, [data]);

  return (
    <>
      <Text marginBottom={1}>Znaleziono {searchResults.length} {searchResults.length === 1 ? 'wynik' : 'wyników'} dla frazy: {searchPhrase}</Text>
      <Block as="ul" marginBottom={2}>
        {searchResults.map((result) => {
          const resultWasteBins = [
            ...(result.binCode ? [result.binCode] : []),
            ...result.wasteCollectionPointTypes.map(t => t.code),
            ...result.otherWasteCollectionPointTypes.map(t => t.code),
          ]
            .map(code => wasteBins[code])
            .filter(wasteBin => !!wasteBin);

          return (
            <Block as="li" padding={3} marginBottom={1} key={result.name} border={defaultBorder}>
              <Flex justifyContent="space-between" marginBottom={result.additionalInfo ? 1 : 3}>
                <Text bold>{result.name}</Text>
                {(resultWasteBins.length > 0 && device >= DEVICE.tablet) && (
                  <PokazNaMapie wasteTypes={resultWasteBins.map(wasteBin => wasteBin.code)} />
                )}
              </Flex>
                {
                    result.additionalInfo &&
                    <Flex marginBottom={3}>
                        <Text>{result.additionalInfo}</Text>
                    </Flex>
                }
              <ul>
                {resultWasteBins
                  .map(wasteBin => (
                    <StyledFlex as="li" alignItems="center" marginBottom={3} key={wasteBin.code}>
                      <FlexItem shrink={0} marginRight={2}>
                        <BlockImg width={8} height={8} src={wasteBin.icon} alt="" />
                      </FlexItem>
                      <FlexItem equal>
                        <Block marginBottom={wasteBin.code === WasteTypeId.pszok ? 1 : 0}>{wasteBin.name}</Block>
                        {(wasteBin.code === WasteTypeId.pszok && pszokPhone) && <Call telephone={pszokPhone} />}
                      </FlexItem>
                    </StyledFlex>
                  ))}
              </ul>
              {(resultWasteBins.length > 0 && device === DEVICE.mobile) && (
                <Block paddingTop={3}>
                  <PokazNaMapie wasteTypes={resultWasteBins.map(wasteType => wasteType.code)} />
                </Block>
              )}
            </Block>
          );
        })}
      </Block>
      <Flex padding={2}>
        <FlexItem shrink={0} marginRight={2}>
          <Flex width={9} justifyContent="flex-end" height="100%" alignItems="center">
            <BlockImg src={search} alt="lupa" width={8} height={8} />
          </Flex>
        </FlexItem>
        <FlexItem equal>
          <Text bold>Nie znalazłeś odpadu?</Text>
          <Text>Spróbuj doprecyzować wyszukiwanie.</Text>
        </FlexItem>
      </Flex>
    </>
  );
};

SearchResults.propTypes = {
  searchResults: PropTypes.arrayOf(PropTypes.object),
  searchPhrase: PropTypes.string.isRequired,
};
export default SearchResults;
