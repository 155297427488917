import { fromPairs } from 'lodash';
import React, { useContext, useEffect, useMemo } from 'react';
import RegulationCodes from '../constants/enums/regulationCodes';
import { apiTabToView } from '../constants/enums/View';
import { PTchildren } from '../constants/propTypes';
import { fetchMapPoints, owner } from '../utils/api';
import useData from '../utils/hooks/useData';
import useListData, { defaultResponseResolver } from '../utils/hooks/useListData';
import { identity } from '../utils/others';
import { useUrlParams } from './UrlParamsProvider';

const OwnerInfoContext = React.createContext();

const wasteToObjectMapTransformer = responseData => fromPairs(Object.values(responseData).map(entity => [entity.code, entity]));

export const OwnerInfoProvider = ({ children }) => {
    const { ownerId } = useUrlParams();
    const { data: info, fetch: fetchInfo } = useData(owner.info, null, defaultResponseResolver, identity);
    const [{ data: regulations }, fetchRegulations] = useListData(owner.regulations, []);
    const [{ data: mapPoints }, fetchMapPointsData] = useListData(fetchMapPoints, [], defaultResponseResolver);
    const {
        data: wasteBins,
        fetch: fetchWasteBins
    } = useData(owner.wasteAll, {}, defaultResponseResolver, wasteToObjectMapTransformer);

    useEffect(() => {
        if (ownerId) {
            fetchInfo(ownerId);
            fetchRegulations(ownerId);
            fetchWasteBins(ownerId);
            fetchMapPointsData(ownerId);
        }
    }, [ownerId, fetchInfo, fetchRegulations, fetchWasteBins, fetchMapPointsData]);

    const value = useMemo(() => {
        const politykaPrywatnosci = regulations.find(r => RegulationCodes.politykaPrywatnosci === r.place.code);
        const politykaCookies = regulations.find(r => RegulationCodes.politykaCookies === r.place.code);
        const regulamin = regulations.find(r => RegulationCodes.regulamin === r.place.code);
        return {
            nip: info ? info.nip : '',
            regon: info ? info.regon : '',
            name: info ? info.displayName || info.gminaName : '',
            address: info ? info.address : '',
            views: info ? info.tabs.map(tab => apiTabToView[tab]).filter(Boolean) : [],
            politykaPrywatnosciURL: politykaPrywatnosci ? politykaPrywatnosci.pdfPath : '',
            politykaCookiesURL: politykaCookies ? politykaCookies.pdfPath : '',
            regulaminURL: regulamin ? regulamin.pdfPath : '',
            wasteBins,
            mapPoints,
        };
    }, [info, mapPoints, regulations, wasteBins]);

    return (
        <OwnerInfoContext.Provider value={ value }>
            { children }
        </OwnerInfoContext.Provider>
    );
};

OwnerInfoProvider.propTypes = {
    children: PTchildren,
};

export const useOwnerInfo = () => {
    const context = useContext(OwnerInfoContext);
    if (context === undefined) {
        throw new Error('useOwnerInfo must be used together with OwnerInfoProvider');
    }
    return context;
};
