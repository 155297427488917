import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as GreenPinSvg } from '../../../../assets/map-pin-green.svg';
import { ReactComponent as RedPinSvg } from '../../../../assets/map-pin-red.svg';
import Flex from '../../../../components/generic/Flex';
import FlexItem from '../../../../components/generic/FlexItem';
import Text from '../../../../components/generic/Text';
import color from '../../../../constants/color';
import { fontSize } from '../../../../constants/font';
import { defaultBorder } from '../../../../constants/style';
import { px } from '../../../../utils/px';

const GreenPin = styled(GreenPinSvg)`
  width: ${px(30)};
`;

const RedPin = styled(RedPinSvg)`
  width: ${px(30)};
`;

const AddressPanel = ({ textAddress, onChangeAddress, available }) => (
  <Flex border={['none', defaultBorder]} padding={[0, 4]} marginBottom={6} as="section">
    <FlexItem marginRight={3}>
      {available ? <GreenPin /> : <RedPin />}
    </FlexItem>
    <FlexItem equal>
      <Text color={available ? undefined : color.validationError} fontSize={fontSize.large} bold>
        {textAddress}
      </Text>
      {!available && (
        <>
          <Text marginBottom={3} color={color.validationError}>Brak harmonogramu dla wybranej lokalizacji.</Text>
          <Text marginBottom={3}>
            Nie odnaleziono harmonogramu dla adresu, który wpisałeś. Jeśli chcesz otrzymać powiadomienie o wywozie odpadów, kiedy harmonogram zostanie
            uzupełniony, zapisz się do newslettera poniżej.
          </Text>
        </>
      )}
      <Text inline color={color.link} onClick={onChangeAddress}>
        Zmień adres
      </Text>
    </FlexItem>
  </Flex>
);

AddressPanel.propTypes = {
  textAddress: PropTypes.string.isRequired,
  onChangeAddress: PropTypes.func.isRequired,
  available: PropTypes.bool,
};
export default AddressPanel;
