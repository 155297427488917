import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from "styled-components";
import { px } from "../../../../utils/px";
import { defaultBorderRadius } from "../../../../constants/style";
import color from "../../../../constants/color";
import { useUrlParams } from "../../../../containers/UrlParamsProvider";
import handleEnter from "../../../../utils/handleEnter";
import { fetchTimetablePdf } from "../../../../utils/api";
import Button from "../../../../components/Button";
import { ReactComponent as SelectArrow } from "../../../../assets/select-arrow.svg";

const DropDown = styled.div`
  position: relative;
  z-index: 1;
`;

const DropDownContent = styled.div`
  ${ props => !props.open && css`
    display: none;
  ` }
  position: absolute;
  top: ${ px(5) };
  right: ${ px(0) };
  width: ${ px(230) };
  border: ${ px(1) } solid ${ props => props.theme.colorAccent };
  border-radius: ${ defaultBorderRadius };
  background-color: #ffffff;
`;

const DropDownItem = styled.p`
  margin: ${ px(0) } ${ px(2) };
  padding: ${ px(8) } ${ px(8) };
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover, &:focus {
    background-color: ${ color.panelBg };
  }
`;

const ArrowIconWhite = styled(SelectArrow)`
  color: ${ color.buttonFont };
  width: ${ px(11) };
  ${ props => props.open && css`
    transform: rotate(180deg);
  ` }
`;

const itemList = [
  { title: 'Wersja półroczna', command: 'semi-annual' },
  { title: 'Wersja miesięczna', command: 'monthly' }
];

const DownloadDropDown = ({ unitId }) => {
  const { ownerId } = useUrlParams();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const onTriggerClick = useCallback(() => {
    setDropdownOpen(true);
  }, []);

  const onDropdownClose = useCallback(() => {
    setDropdownOpen(false)
  }, []);

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener("click", onDropdownClose);
      document.addEventListener("keypress", onDropdownClose);
    } else {
      document.removeEventListener("click", onDropdownClose);
      document.removeEventListener("keypress", onDropdownClose);
    }
  }, [dropdownOpen, onDropdownClose]);

  return (
    <>
      <Button style={ { width: px(230), justifyContent: 'space-between' } } onClick={ onTriggerClick } small>
        <div>Pobierz harmonogram w pdf</div>
        <ArrowIconWhite open={ dropdownOpen }/>
      </Button>
      <DropDown>
        <DropDownContent open={ dropdownOpen }>
          { itemList.map(item => {
            return (
              <DropDownItem
                key={ item.title }
                onClick={ () => fetchTimetablePdf(ownerId, unitId, item.command) }
                onKeyDown={ () => handleEnter(fetchTimetablePdf(ownerId, unitId, item.command)) }
                tabIndex="0">
                { item.title }
              </DropDownItem>
            )
          }) }
        </DropDownContent>
      </DropDown>
    </>
  )

};

export default DownloadDropDown;