import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import handleEnter from '../utils/handleEnter';
import hexToRgb from '../utils/hexToRgb';
import { su } from '../utils/styledComponents';
import FlexItem from './generic/FlexItem';
import Text from './generic/Text';
import RoundArrowButton from './RoundArrowButton';
import { ReactComponent as ArrowRight } from '../assets/arrow-right.svg';

const Box = styled.div`
  background-color: ${props => `rgba(${hexToRgb(props.theme.colorAccent)}, 0.2)`};
  &:hover {
    background-color: ${props => `rgba(${hexToRgb(props.theme.colorAccent)}, 0.4)`};
  }
  transition: background-color 250ms ease-in-out;
  padding: ${su(3)};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ColorPanel = ({ title, onClick }) => (
  <Box tabIndex="0" onClick={onClick} onKeyDown={handleEnter(onClick)}>
    <FlexItem equal>
      <Text bold>{title}</Text>
    </FlexItem>
    <FlexItem shrink={0}>
      <RoundArrowButton as="div" icon={ArrowRight} />
    </FlexItem>
  </Box>
);

ColorPanel.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default ColorPanel;
