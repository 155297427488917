import PropTypes from 'prop-types';
import React from 'react';
import Truncate from 'react-truncate';
import styled from 'styled-components';
import { followCursor } from 'tippy.js';
import BlockImg from '../../../../../components/generic/BlockImg';
import Flex from '../../../../../components/generic/Flex';
import Text from '../../../../../components/generic/Text';
import Tippy from '../../../../../components/Tippy';
import color from '../../../../../constants/color';
import { fontSize } from '../../../../../constants/font';
import { useOwnerInfo } from '../../../../../containers/OwnerInfoProvider';
import { px } from '../../../../../utils/px';
import { styleCss } from '../../../../../utils/styledComponents';

const BinBox = styled.div`
  padding: ${px(4)} ${px(4)}; 
  max-height: ${px(50)}; 
  ${styleCss('bg', 'background')};
  ${styleCss('color')};
  margin-bottom: ${px(2)};
  line-height: 1.2;
`;

const Bin = ({ bin }) => {
  const { wasteBins } = useOwnerInfo();
  const wasteBin = bin.type ? wasteBins[bin.type] : null;
  return (
    <Tippy
      key={bin.id}
      arrow={false}
      plugins={[followCursor]}
      followCursor
      content={
        <Flex alignItems="center">
          {wasteBin && <BlockImg width={6} height={6} src={wasteBin.icon} alt={bin.name} marginRight={1.4} />}
          <Text fontSize={fontSize.semiSmall}>{bin.name}</Text>
        </Flex>
      }
      duration={0}
      placement="right-start" // should be right-end, but seems reversed. Track https://github.com/atomiks/tippy.js-react/issues/159
    >
      <BinBox
        bg={wasteBin ? wasteBin.color : bin.color || color.wasteSpecial}
        color={wasteBin ? (wasteBin.fontColor || color.fontDefault) : '#ffffff'}
      >
        <div>
          <Truncate
            lines={2}
            ellipsis="..."
          >
            {bin.name}
          </Truncate>
        </div>
      </BinBox>
    </Tippy>
  );
};

Bin.propTypes = {
  bin: PropTypes.object,
};
export default Bin;
