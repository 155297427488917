import React from 'react';
import useToggle from 'react-use/lib/useToggle';
import CollapseSwitch from '../../../components/CollapseSwitch';
import Block from '../../../components/generic/Block';
import Text from '../../../components/generic/Text';
import Link from '../../../components/Link';
import { fontSize } from '../../../constants/font';
import { useOwnerInfo } from '../../../containers/OwnerInfoProvider';

const AdministrationNoticeAddress = (props) => {
  const [showFullDataNotice, toggleShowFullDataNotice] = useToggle(false);
  const { name, nip, regon, address, regulaminURL, politykaPrywatnosciURL } = useOwnerInfo();

  return (
    <Text fontSize={fontSize.semiSmall} {...props}>
      <Block>
        Administratorem danych osobowych jest: {name}, {address}, NIP: {nip}; REGON: {regon}. Dane są
        przetwarzane w celu
        świadczenia
        usługi zgodnie z <Link external inline href={regulaminURL}>Regulaminem</Link>
        {showFullDataNotice && (
          <>
            &nbsp;polegającej na wysyłaniu zgłoszeń i powiadomień oraz dostępu do informacji na temat gospodarki odpadami na podany adres poczty elektronicznej,
            w tym
            na elektroniczne urządzenia końcowe. Osoba ma prawo dostępu do swoich danych osobowych, do ich
            sprostowania, usunięcia lub ograniczenia przetwarzania, do wniesienia sprzeciwu wobec przetwarzania i wniesienia skargi do organu nadzorczego. W
            przypadku przetwarzania danych na podstawie zgody, osoba ma prawo do jej wycofania w każdym czasie. Więcej&nbsp;
            <Link external inline href={politykaPrywatnosciURL}>tutaj</Link>.
          </>
        )}
      </Block>
      <CollapseSwitch
        isOpen={showFullDataNotice}
        onToggle={toggleShowFullDataNotice}
        closedText="Rozwiń"
        openText="Zwiń"
        fontSize={fontSize.semiSmall}
        withIcon
      />
    </Text>
  );
};

AdministrationNoticeAddress.propTypes = {};

export default AdministrationNoticeAddress;
