import PropTypes from 'prop-types';
import React from 'react';
import BlockImg from '../../../components/generic/BlockImg';
import Flex from '../../../components/generic/Flex';
import FlexItem from '../../../components/generic/FlexItem';
import Text from '../../../components/generic/Text';
import Panel from '../../../components/Panel';
import Table from '../../../components/table/Table';
import TableBody from '../../../components/table/TableBody';
import TableCell from '../../../components/table/TableCell';
import TableHeader from '../../../components/table/TableHeader';
import TableListItem from '../../../components/table/TableListItem';
import color from '../../../constants/color';
import { px } from '../../../utils/px';

const RodzajeKontenerowTablet = ({ content }) => (
  <Panel>
    <Table>
      <thead>
        <tr>
          <TableHeader width="25%">Kontener</TableHeader>
          <TableHeader width="37.5%">Co wrzucać?</TableHeader>
          <TableHeader width="37.5%">Czego nie wrzucać?</TableHeader>
        </tr>
      </thead>
      <TableBody>
        {content.map(({ icon, description, allowed, disallowed }, index) => (
          <tr key={index}>
            <TableCell width="25%">
              <Flex alignItems="center" marginBottom={3}>
                <FlexItem shrink={0}>
                  {icon && <BlockImg src={icon} alt="" width={px(40)} marginRight={2} />}
                </FlexItem>
                <FlexItem equal>
                  <Text bold>{description}</Text>
                </FlexItem>
              </Flex>
            </TableCell>
            <TableCell width="37.5%">
              <ul>
                {allowed.map((allowedEntry, okIndex) => (
                  <TableListItem key={okIndex} color={color.throwAllowed}>{allowedEntry}</TableListItem>
                ))}
              </ul>
            </TableCell>
            <TableCell width="37.5%">
              <ul>
                {disallowed.map((disallowedEntry, notOkIndex) => (
                  <TableListItem key={notOkIndex} color={color.throwDisallowed}>{disallowedEntry}</TableListItem>
                ))}
              </ul>
            </TableCell>
          </tr>
        ))}
      </TableBody>
    </Table>
  </Panel>
);
RodzajeKontenerowTablet.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RodzajeKontenerowTablet;
