import PropTypes from 'prop-types';
import React from 'react';
import useToggle from 'react-use/lib/useToggle';
import styled, { css } from 'styled-components';
import CollapseSwitch from '../../../components/CollapseSwitch';
import Block from '../../../components/generic/Block';
import Flex from '../../../components/generic/Flex';
import FlexItem from '../../../components/generic/FlexItem';
import Text from '../../../components/generic/Text';
import color from '../../../constants/color';
import { defaultBorder, tableBorder } from '../../../constants/style';
import hexToRgb from '../../../utils/hexToRgb';
import { px } from '../../../utils/px';

const RoundIconBox = styled.div`
  background-color: #f3f3f3;
  fill: ${color.navItem};
  ${props => props.active && css`
    background-color: rgba(${hexToRgb(props.theme.colorAccent)}, 0.2);
    fill: ${props.theme.colorAccent};
  `};
  height: ${px(45)};
  width: ${px(45)};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WartoWiedziecItem = ({ Icon, title, description, MoreContent, ...props }) => {
  const [isOpen, toggleOpen] = useToggle(false);
  return (
    <Block marginBottom={1} border={defaultBorder} {...props}>
      <Flex padding={3}>
        <FlexItem shrink={0} marginRight={3}>
          <RoundIconBox active={isOpen}>
            <Icon style={{ width: px(45), height: px(45) }} />
          </RoundIconBox>
        </FlexItem>
        <FlexItem equal>
          <Text as="h2" bold marginBottom={1}>{title}</Text>
          <Block marginBottom={MoreContent ? 2 : 0}>{description}</Block>
          {MoreContent && (
            <CollapseSwitch isOpen={isOpen} onToggle={toggleOpen} closedText="Dowiedz się więcej" openText="Zwiń" />
          )}
        </FlexItem>
      </Flex>
      {isOpen && (
        <Block style={{ borderTop: tableBorder }}>
          {MoreContent}
        </Block>
      )}
    </Block>
  );
};

WartoWiedziecItem.propTypes = {
  Icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  MoreContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
export default WartoWiedziecItem;
