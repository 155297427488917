import PropTypes from 'prop-types';
import React from 'react';
import error from '../assets/icon_newsletter--failure.svg';
import Block from './generic/Block';
import BlockImg from './generic/BlockImg';
import Grid from './generic/Grid';
import Text from './generic/Text';
import color from '../constants/color';
import { fontWeight } from '../constants/font';
import { px } from '../utils/px';

const FormNotSent = ({ retry }) => (
  <Grid columns={`${px(27)} auto`} rows="auto auto" gap={`${px(10)} ${px(5)}`} alignItems="center">
    <BlockImg src={error} width="100%" />
    <Block paddingTop={px(6)}>
      <Text fontWeight={fontWeight.bold} color={color.formNotSent}>Formularz nie został przesłany</Text>
    </Block>
    <div />
    <div>
      <Text marginBottom={1}>Przepraszamy, wystąpił błąd.</Text>
      <Text onClick={retry} color={color.link}>Prześlij ponownie</Text>
    </div>
  </Grid>
);

FormNotSent.propTypes = {
  retry: PropTypes.func.isRequired,
};

export default FormNotSent;
