import styled, { css } from 'styled-components';
import { defaultBorderRadius } from '../../constants/style';
import { px } from '../../utils/px';

const ItemsList = styled.ul`
  ${props => !props.isOpen && css`
    display: none;
  `};
  position: absolute;
  top: 100%;
  z-index: 4;
  transform: translateY(-${px(4)});
  width: 100%;
  max-height: ${px(300)};
  overflow-y: auto;
  padding: 0;
  margin: 0;
  border: ${props => px(props.theme.dimensionInputActiveBorder)} solid ${props => props.theme.colorInputActive};
  border-bottom-left-radius: ${defaultBorderRadius};
  border-bottom-right-radius: ${defaultBorderRadius};
  background: #ffffff;
`;

export default ItemsList;
