import * as PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import color from '../../constants/color';
import { fontWeight } from '../../constants/font';
import { px } from '../../utils/px';
import { styleCss } from '../../utils/styledComponents';

const Item = styled.li`
  display: block;
  color: ${color.fontDefault};
  padding: ${px(5)} ${px(14)};
  width: 100%;
  user-select: none;
  &:last-of-type {
    border-bottom-left-radius: ${px(4)};
    border-bottom-right-radius: ${px(4)};
  }
  ${props => props.highlighted && css`
    background-color: #d5d5d9;
  `};
  ${props => props.selected && css`
    background-color: #d5d5d9;
    font-weight: ${fontWeight.medium};
  `};
  ${styleCss('fontSize')};
`;

Item.propTypes = {
  fontSize: PropTypes.string,
};

export default Item;
