import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from '../generic/Text';

const FormLabel = styled(Text.withComponent('label'))``;
FormLabel.defaultProps = {
  marginBottom: 1,
};
FormLabel.propTypes = {
  htmlFor: PropTypes.string,
  ...Text.propTypes,
};
export default FormLabel;
