import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import uuid from 'uuid/v4';
import { ReactComponent as TickIcon } from '../../assets/tick.svg';
import color from '../../constants/color';
import { PTspacing } from '../../constants/propTypes';
import { px } from '../../utils/px';
import { styleCssSpacing } from '../../utils/styledComponents';
import Flex from '../generic/Flex';
import FlexItem from '../generic/FlexItem';
import Text from '../generic/Text';

const Tick = styled(TickIcon)``;

const HiddenInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: -1;
`;
const Box = styled.div`
  position: relative;
  top: 1px;
  display: block;
  width: ${px(14)};
  height: ${px(14)};
  background-color: #ffffff;
  fill: ${color.link};
  border: ${px(1)} solid #d5d5d9;
  border-radius: ${px(3)};
  cursor: pointer;
  ${props => props.disabled && css`
    background-color: #f1f1f1;
    fill: #a7a7a7;
  `};
  
  ${styleCssSpacing('marginRight')}
  
  ${Tick} {
    position: absolute;
    top: ${px(1)};
    left: ${px(2)};
    width: ${px(12)};
    height: ${px(9)};
  }
`;

const Label = styled.label`
  ${HiddenInput}:focus ~ ${Box} {
    border: ${px(1)} solid ${color.link};
    box-shadow: 0 0 0 ${px(1)} ${color.link};
  }
`;

/** It uses a popular trick, that clicking a label with for="id" attribute interacts with the checkbox of same id,
 *  which is hidden, and we get to display our own graphical representation */
const FormCheckbox = ({ id: givenId, label, name, checked, onChange, disabled, marginBottom, alignTop, ...props }) => {
  const id = useMemo(() => givenId || uuid(), [givenId]);
  return (
    <Label htmlFor={id}>
      <Flex alignItems={alignTop ? 'flex-start' : 'center'} marginBottom={marginBottom}>
        <FlexItem shrink={0} paddingTop={alignTop ? px(3) : undefined} relative>
          <HiddenInput
            id={id}
            type="checkbox"
            name={name}
            onChange={onChange}
            disabled={disabled}
            {...props}
          />
          <Box disabled={disabled} marginRight={label ? px(8) : undefined}>
            {checked && (
              <Tick />
            )}
          </Box>
        </FlexItem>
        <FlexItem equal>
          <Text>{label}</Text>
        </FlexItem>
      </Flex>
    </Label>
  );
};

FormCheckbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  marginBottom: PTspacing,
  alignTop: PropTypes.bool,
};

export default React.memo(FormCheckbox);
