import RawTippy from '@tippy.js/react';
import styled from 'styled-components';
import 'tippy.js/dist/tippy.css';
import color from '../constants/color';
import { px } from '../utils/px';
import { su } from '../utils/styledComponents';

const Tippy = styled(RawTippy)`
  &.tippy-tooltip {
    color: ${color.fontDefault};
    box-shadow: 0 ${px(3)} ${px(6)} 0 rgba(0, 0, 0, 0.16);
    padding: ${su(2)};
    border-radius: 0;
    background-color: #ffffff;
  }
`;

export default Tippy;
