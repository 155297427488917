import { useCallback, useState } from 'react';
import useMountedState from 'react-use/lib/useMountedState';

export const defaultResponseResolver = res => res.data;
export const nestedResponseResolver = res => res.data.data;

const useListData = (
  apiCallFn,
  initialData,
  responseResolver = defaultResponseResolver,
) => {
  const [state, setState] = useState({ data: initialData, error: null, pending: false });

  const isMounted = useMountedState();

  const fetch = useCallback(
    async (...args) => {
      setState(prevState => ({ ...prevState, error: null, pending: true }));
      try {
        const response = await apiCallFn(...args);
        if (isMounted()) {
          const data = responseResolver(response);
          setState(prevState => ({ ...prevState, data, error: null, pending: false }));
        }
      } catch (error) {
        if (isMounted()) {
          console.error(error);
          setState(prevState => ({ ...prevState, error, pending: false }));
        }
      }
    },
    [apiCallFn, isMounted, responseResolver],
  );

  const setData = useCallback((data) => {
    setState(prevState => ({ ...prevState, data }));
  }, []);

  return [state, fetch, setData];
};

export default useListData;
