import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fontWeight } from '../../constants/font';
import { styleCss, su } from '../../utils/styledComponents';

const TableHeaderDiv = styled.div`
  padding: ${su(1)} ${su(2)};
  background-color: #36354d;
  
  ${styleCss('textAlign')};
  
  font-weight: ${fontWeight.bold};
  text-transform: uppercase;
  color: #ffffff;
`;
TableHeaderDiv.propTypes = {
  textAlign: PropTypes.string,
};

export default TableHeaderDiv;
