import { useEffect, useMemo } from 'react';
import { useUrlParams } from '../../containers/UrlParamsProvider';
import {
  fetchBins as fetchBinsApiCall,
  fetchPaymentPeriods as fetchPaymentPeriodsApiCall,
  fetchTimetable as fetchTimetableApiCall
} from '../../utils/api';
import useListData, { nestedResponseResolver } from '../../utils/hooks/useListData';

const useTimetableData = (addressId) => {
  const { ownerId } = useUrlParams();
  const [timetable, fetchTimetable] = useListData(fetchTimetableApiCall, null, nestedResponseResolver);
  const [bins, fetchBins] = useListData(fetchBinsApiCall, null, nestedResponseResolver);
  const [paymentPeriods, fetchPaymentPeriods] = useListData(fetchPaymentPeriodsApiCall, null, nestedResponseResolver);

  useEffect(() => {
    fetchTimetable(ownerId, addressId);
    fetchBins(ownerId, addressId);
    fetchPaymentPeriods(ownerId, addressId);
  }, [fetchTimetable, fetchBins, addressId, ownerId, fetchPaymentPeriods]);

  return useMemo(() => ({
    timetable: timetable.data,
    bins: bins.data,
    paymentPeriods: paymentPeriods.data,
    pending: timetable.pending || bins.pending || paymentPeriods.pending,
    error: timetable.error || bins.error || paymentPeriods.error,
  }), [
    bins.data,
    bins.error,
    bins.pending,
    paymentPeriods.data,
    paymentPeriods.error,
    paymentPeriods.pending,
    timetable.data,
    timetable.error,
    timetable.pending,
  ]);
};

export default useTimetableData;
