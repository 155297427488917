import PropTypes from 'prop-types';
import styled from 'styled-components';
import Block from './Block';

const BlockImg = styled(Block.withComponent('img'))`
  display: block;
`;

BlockImg.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default BlockImg;
