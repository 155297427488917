import PropTypes from 'prop-types';
import * as React from 'react';
import Flex from '../../../../../components/generic/Flex';
import FlexItem from '../../../../../components/generic/FlexItem';
import TableHeaderDiv from '../../../../../components/table/TableHeaderDiv';
import { tableBorder } from '../../../../../constants/style';
import Day from './Day';

const DAYS_OF_WEEK = ['PON.', 'WT.', 'ŚR.', 'CZW.', 'PT.', 'SOB.', 'NIEDZ.'];

const CalendarLayoutTablet = ({ days }) => (
  <>
    <Flex>
      {DAYS_OF_WEEK.map(day => (
        <FlexItem key={day} equal>
          <TableHeaderDiv>
            {day}
          </TableHeaderDiv>
        </FlexItem>
      ))}
    </Flex>
    <Flex flexWrap as="ul" bg="#ffffff" style={{ borderLeft: tableBorder }}>
      {days.map((day, index) => (
        <FlexItem as="li" key={index} width={`${100 / 7}%`} tabIndex="0" style={{ borderBottom: tableBorder, borderRight: tableBorder }}>
          <Day day={day} />
        </FlexItem>
      ))}
    </Flex>
  </>
);

CalendarLayoutTablet.propTypes = {
  days: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CalendarLayoutTablet;
