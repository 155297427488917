import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { MEDIA_CSS } from '../constants/breakpoints';
import { fontSize, fontWeight } from '../constants/font';
import Text from './generic/Text';

const PageHeader = styled(Text.withComponent('h1'))`
  ${props => props.center && css`
    text-align: center;
  `};
  
  font-size: ${props => props.fontSize || fontSize.extraLargeMobile};
  @media (${MEDIA_CSS.tablet}) {
    font-size: ${props => props.fontSize || fontSize.extraLarge};
  }
`;
PageHeader.defaultProps = {
  fontWeight: fontWeight.bold,
  marginBottom: 3,
};
PageHeader.propTypes = {
  ...Text.propTypes,
  center: PropTypes.bool,
};

export default PageHeader;
