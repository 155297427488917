import React from 'react';
import ColorPanel from '../../../components/ColorPanel';
import Grid from '../../../components/generic/Grid';
import { View } from '../../../constants/enums/View';
import { useNavigation } from '../../../containers/NavigationProvider';
import { useOwnerInfo } from '../../../containers/OwnerInfoProvider';

const Links = () => {
    const { views } = useOwnerInfo();
    const { goToView } = useNavigation();

    return (
        <Grid rows="auto" columns={ ['1fr', '1fr 1fr'] } gap={ [2, 3] } marginBottom={ [6, 9] } as="section">
            {
                views.includes(View.zglos) &&
                <ColorPanel title="Nie odebrano Twoich odpadów? Zgłoś to!" onClick={ () => goToView(View.zglos) }/>
            }
            {
                views.includes(View.gdzieWyrzucic) &&
                <ColorPanel
                    title="Nie wiesz, gdzie wyrzucić odpad? Sprawdź!"
                    onClick={ () => goToView(View.gdzieWyrzucic) }
                />
            }
        </Grid>
    );
};

Links.propTypes = {};

export default Links;
