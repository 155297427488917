const getNonEmptyBins = (mapPoints) => {
  const bins = new Set();
  mapPoints.forEach((mapPoint) => {
    mapPoint.types.forEach((type) => {
      bins.add(type);
    });
  });
  return bins;
};
// chcemy ukryć z filtrów te kosze, do których i tak nie ma punktów na mapie
export const getWasteBinsToShow = (mapPoints, wasteBins) => {
  const nonEmptyBinsCodesSet = getNonEmptyBins(mapPoints);
  return Object.values(wasteBins).filter(bin => bin.showOnMap && nonEmptyBinsCodesSet.has(bin.code));
};
