import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { styleCss } from '../../utils/styledComponents';
import Block from './Block';

const Flex = styled(Block)`
  display: ${props => props.inline ? 'inline-flex' : 'flex'};
  flex-direction: ${props => (props.column && 'column') || 'row'}${props => props.reverse && '-reverse'};
  ${props => props.flexWrap && css`flex-wrap: wrap;`};
  ${styleCss('justifyContent')};
  ${styleCss('alignContent')};
  ${styleCss('alignItems')};
`;

Flex.propTypes = {
  ...Block.propTypes,
  inline: PropTypes.bool,
  column: PropTypes.bool,
  reverse: PropTypes.bool,
  flexWrap: PropTypes.bool,
  justifyContent: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly']),
  alignContent: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'stretch']),
  alignItems: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'baseline', 'stretch']),
};

export default Flex;
