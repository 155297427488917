import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Flex from '../../../components/generic/Flex';
import FlexItem from '../../../components/generic/FlexItem';
import Text from '../../../components/generic/Text';
import { su } from '../../../utils/styledComponents';

const MediaObjectContainer = styled.div`
  margin-bottom: ${su(3)};
  &:last-of-type {
    margin-bottom: 0;
  }
`;
export const MediaObject = ({ PicturePart, title, description, ...props }) => (
  <MediaObjectContainer {...props}>
    <Flex>
      <FlexItem shrink={0} marginRight={3}>
        <Flex justifyContent="flex-end" width={9}>
          {PicturePart}
        </Flex>
      </FlexItem>
      <FlexItem equal>
        <Text bold marginBottom={1}>{title}</Text>
        <div>{description}</div>
      </FlexItem>
    </Flex>
  </MediaObjectContainer>
);
MediaObject.propTypes = {
  PicturePart: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};
