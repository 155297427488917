import React from 'react';
import styled from 'styled-components';
import appStore from '../../assets/app-strore-btn.svg';
import googlePlay from '../../assets/google-play-btn.svg';
import blisko from '../../assets/znak-blisko.png';
import blisko2x from '../../assets/znak-blisko@2x.png';
import blisko3x from '../../assets/znak-blisko@3x.png';
import BlockImg from '../../components/generic/BlockImg';
import Flex from '../../components/generic/Flex';
import FlexItem from '../../components/generic/FlexItem';
import Text from '../../components/generic/Text';
import Link from '../../components/Link';
import NoBreaker from '../../components/NoBreaker';
import color from '../../constants/color';
import { fontSize, fontWeight } from '../../constants/font';
import { px } from '../../utils/px';
import { pxMediaQueryEM } from '../../utils/pxMediaQueryEM';
import srcSet from '../../utils/srcSet';

const BreakingFlexItem = styled(FlexItem)`
  display: none;
  @media(min-width: ${pxMediaQueryEM(500)}) {
    display: block;
  }
`;

const PaddingChangingFlex = styled(Flex)`
  padding: ${px(20)};
  @media(min-width: ${pxMediaQueryEM(500)}) {
    padding: ${px(10)};
  }
`;

const Header = styled(Text)`
  @media(min-width: ${pxMediaQueryEM(500)}) {
    margin-top: ${px(14)};
  }
`;

const AddressBaner = () => (
  <PaddingChangingFlex bg={color.bannerBg} justifyContent="center">
    <Flex justifyContent="center" maxWidth={px(590)}>
      <Flex>
        <BreakingFlexItem grow={0} width={px(75)} marginRight={px(15)}>
          <BlockImg src={blisko} srcSet={srcSet(blisko2x, blisko3x)} width="100%" alt="logo aplikacji Blisko" />
        </BreakingFlexItem>
        <FlexItem equal>
          <Header fontSize={fontSize.large} fontWeight={fontWeight.bold} color="#ffffff" lineHeight="1.33" marginBottom={px(10)}>
          Pobierz bezpłatną aplikację BLISKO
          </Header>
          <Text color="#ffffff" marginBottom={px(20)}>
            Korzystaj z informacji o gospodarowaniu odpadami <NoBreaker>w telefonie</NoBreaker>
          </Text>
          <Flex>
            <Link external href="https://play.google.com/store/apps/details?id=sisms.groups_only">
              <BlockImg src={googlePlay} marginRight={1} width={px(135)} alt="Pobierz z Google Play" />
            </Link>
            <Link external href="https://apps.apple.com/pl/app/blisko/id821655959?l=pl">
              <BlockImg src={appStore} width={px(135)} alt="Pobierz w App Store" />
            </Link>
          </Flex>
        </FlexItem>
      </Flex>
    </Flex>
  </PaddingChangingFlex>
);

export default AddressBaner;
