/* eslint-disable max-len */
import React from 'react';
import BlockImg from '../../../components/generic/BlockImg';
import Panel from '../../../components/Panel';
import { MediaObject } from '../components/MediaObject';
import { content } from './content';

const OznakowaniaNaOpakowaniach = () => (
  <Panel>
    <ul>
      {content.map(({ icon, title, description }) => (
        <MediaObject
          as="li"
          key={title}
          PicturePart={<BlockImg src={icon} alt={title} width={8} height={8} />}
          title={title}
          description={description}
        />
      ))}
    </ul>
  </Panel>
);

export default OznakowaniaNaOpakowaniach;
