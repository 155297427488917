/* eslint-disable max-len */
import aluminium from './assets/aluminium.png';
import biodegradowalne from './assets/biodegradowalne.png';
import ce from './assets/ce.png';
import dbajOCzystosc from './assets/dbaj-o-czystosc.png';
import doPonownego from './assets/do-ponownego-wykorzystania.png';
import doRecyklingu from './assets/do-recyklingu.svg';
import przekreslonyKontener from './assets/przekreslony-kontener.svg';
import przydatnoscDoRecyklingu from './assets/przydatnosc-do-recyklingu.png';
import zielonyPunkt from './assets/zielony-punkt.png';

export const content = [
  {
    icon: zielonyPunkt,
    title: 'Zielony punkt',
    description: 'Producent sfinansował budowę i funkcjonowanie systemu odzysku odpadów. Znak jest umieszczany na ponad 460 miliardach opakowań rocznie.',
  },
  {
    icon: dbajOCzystosc,
    title: 'Dbaj o czystość',
    description: 'Przypomnienie o tym, aby nie zaśmiecać środowiska i produkt wyrzucić do kosza.',
  },
  {
    icon: doRecyklingu,
    title: 'Opakowanie nadaje się do recyklingu',
    description: 'Opakowanie jest wytworzone z surowców podatnych na recyklingu.',
  },
  {
    icon: ce,
    title: 'CE (Conformité Européenne)',
    description: 'Producent umieszcza logo na wyrobie jako deklarację, że jego wyrób spełnia wymogi dyrektyw  Unii Europejskiej. Pojawia się np. na zabawkach i sprzęcie elektronicznym.',
  },
  {
    icon: przydatnoscDoRecyklingu,
    title: 'Przydatność do recyklingu',
    description: 'Opakowanie nadające się do recyklingu.',
  },
  {
    icon: biodegradowalne,
    title: 'Opakowanie biodegradowalne',
    description: 'Opakowanie rozkłada się podczas kompostowania i nie uwalnia w procesie rozkładu szkodliwych substancji np. torba papierowa.',
  },
  {
    icon: aluminium,
    title: 'Aluminium',
    description: 'Opakowanie z aluminium nadaje się do recyklingu. 100% aluminium może zostać odzyskane w procesie recyklingu.',
  },
  {
    icon: doPonownego,
    title: 'Do ponownego wykorzystania',
    description: 'Możliwość wielokrotnego użytku. Wykorzystane opakowanie nie jest odpadem, powinno nadawać się do użytku przynajmniej dwukrotnie.',
  },
  {
    icon: przekreslonyKontener,
    title: 'Przekreślony kontener',
    description: 'Przedmiot oznaczony znakiem nie wolno wyrzucać z innymi odpadami. Należy oddać go do punktu PSZOK, lub do sklepu RTV AGD.',
  },
];
