import React, { useCallback, useMemo, useState } from 'react';
import Block from '../../components/generic/Block';
import Flex from '../../components/generic/Flex';
import Text from '../../components/generic/Text';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import color from '../../constants/color';
import { useUrlParams } from '../../containers/UrlParamsProvider';
import { wasteSearchEngine } from '../../utils/api';
import useListData from '../../utils/hooks/useListData';
import NoSearchResults from './components/NoSearchResults';
import SearchBox from './components/SearchBox';
import SearchResults from './components/SearchResults';

const GdzieWyrzucic = () => {
  const { ownerId } = useUrlParams();
  const searchFn = useMemo(() => phrase => wasteSearchEngine.search(ownerId, phrase), [ownerId]);
  const [{ data, pending, error }, searchWaste] = useListData(searchFn, null);
  const [searchPhrase, setSearchPhrase] = useState(null);

  const search = useCallback((phrase) => {
    setSearchPhrase(phrase);
    searchWaste(phrase);
  }, [searchWaste]);

  const renderContent = useCallback(() => {
    if (pending) {
      return (
        <Flex justifyContent="center">
          <Spinner width={6} />
        </Flex>
      );
    }
    if (error) {
      return (
        <Flex justifyContent="center">
          <Text color={color.validationError}>Błąd wyszukiwania</Text>
        </Flex>
      );
    }

    if (data) {
      if (data.length > 0) {
        return <SearchResults searchResults={data} searchPhrase={searchPhrase} />;
      }
      return <NoSearchResults searchPhrase={searchPhrase} />;
    }
    return null;
  }, [data, error, pending, searchPhrase]);

  return (
    <Block marginBottom={2}>
      <PageHeader>Nie wiesz, gdzie wyrzucić odpad? Sprawdź!</PageHeader>
      <SearchBox search={search} />
      {renderContent()}
    </Block>
  );
};

GdzieWyrzucic.propTypes = {};

export default GdzieWyrzucic;
