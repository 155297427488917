import { rgba } from 'polished';
import PropTypes from 'prop-types';
import * as React from 'react';
import useToggle from 'react-use/lib/useToggle';
import paymentDay from '../../../../assets/paymentDay.svg';
import Button from '../../../../components/Button';
import Block from '../../../../components/generic/Block';
import BlockImg from '../../../../components/generic/BlockImg';
import Flex from '../../../../components/generic/Flex';
import FlexItem from '../../../../components/generic/FlexItem';
import Text from '../../../../components/generic/Text';
import color from '../../../../constants/color';
import { fontSize, fontWeight } from '../../../../constants/font';
import { useOwnerInfo } from '../../../../containers/OwnerInfoProvider';
import { px } from '../../../../utils/px';

const DAYS_OF_WEEK = ['poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota', 'niedziela'];
const MONTHS = [
  'STYCZEŃ',
  'LUTY',
  'MARZEC',
  'KWIECIEŃ',
  'MAJ',
  'CZERWIEC',
  'LIPIEC',
  'SIERPIEŃ',
  'WRZESIEŃ',
  'PAŹDZIERNIK',
  'LISTOPAD',
  'GRUDZIEŃ',
];

const DAYS_TO_SHOW_INITIALLY = 5;

const PaymentNotice = () => (
  <Block padding={2} marginBottom={3} border={`1px solid ${color.paymentDayFont}`}>
    <Flex alignItems="center" marginBottom={2}>
      <BlockImg width="19px" height="18px" src={paymentDay} alt="Termin opłaty" marginRight={px(7)} />
      <Text fontWeight={fontWeight.bold} color={color.paymentDayFont}>Termin opłaty</Text>
    </Flex>
    <Text textAlign="start">
      Opłatę za gospodarowanie odpadami komunalnymi należy uiszczać bez wezwania. Wysokość opłaty wynika ze złożonej deklaracji.
    </Text>
  </Block>
);

const CalendarLayoutMobile = ({ days }) => {
  const { wasteBins } = useOwnerInfo();
  const daysWorthShowing = days.filter(day => !day.outside && (day.bins.length > 0 || day.isPaymentDay));
  const [showAll, toggleShowAll] = useToggle(daysWorthShowing <= DAYS_TO_SHOW_INITIALLY);
  const daysToShow = showAll ? daysWorthShowing : daysWorthShowing.slice(0, DAYS_TO_SHOW_INITIALLY);
  return (
    <Block as="ul">
      {daysToShow.map((day, index) => (
        <Block as="li" key={index} paddingBottom={3}>
          <Flex alignItems="center">
            <FlexItem shrink={0} marginRight={2}>
              <Text bold fontSize={fontSize.semiLarge} marginBottom={1}>
                {`${day.date.date()} ${MONTHS[day.date.month()]}, ${DAYS_OF_WEEK[day.date.isoWeekday() - 1]}`}
              </Text>
            </FlexItem>
            <FlexItem grow={1} style={{ borderBottom: `1px solid ${rgba(color.fontDefault, 0.1)}` }} />
          </Flex>
          {day.isPaymentDay && <PaymentNotice />}
          {day.bins.map((bin, binIndex) => {
            const wasteBin = bin.type ? wasteBins[bin.type] : null;
            return (
              <Block
                key={binIndex}
                paddingH={px(8)}
                paddingV={px(5)}
                bg={wasteBin ? wasteBin.color : bin.color || color.wasteSpecial}
                marginBottom={1}
              >
                <Text bold color={wasteBin ? (wasteBin.fontColor || color.fontDefault) : '#ffffff'}>
                  {bin.name}
                </Text>
              </Block>
            );
          })}
        </Block>
      ))}
      {!showAll && (
        <Flex justifyContent="center" marginBottom={3}>
          <Button small onClick={toggleShowAll}>Pokaż cały miesiąc</Button>
        </Flex>
      )}
    </Block>
  );
};

CalendarLayoutMobile.propTypes = {
  days: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CalendarLayoutMobile;
