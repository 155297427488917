import { useCallback, useEffect, useState } from 'react';
import { useUrlParams } from '../../containers/UrlParamsProvider';
import { unit } from '../../utils/api';
import useListData, { nestedResponseResolver } from '../../utils/hooks/useListData';

const useUnitData = () => {
  const { ownerId } = useUrlParams();
  const [towns, fetchTowns] = useListData(unit.towns, [], nestedResponseResolver);
  const [streets, fetchStreets, setStreetsData] = useListData(unit.streets, [], nestedResponseResolver);
  const [
    streetAddresses, fetchStreetAddresses, setStreetAddressesData,
  ] = useListData(unit.streetAddresses, [], nestedResponseResolver);
  const [townAddresses, fetchTownAddresses] = useListData(unit.townAddresses, [], nestedResponseResolver);
  const [selectedTown, setSelectedTown] = useState(null);
  const [selectedStreet, setSelectedStreet] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);

  useEffect(() => {
    fetchTowns(ownerId);
  }, [fetchTowns, ownerId]);

  const selectTown = useCallback((town) => {
    setSelectedTown(town);
    setSelectedStreet(null);
    setSelectedAddress(null);
    setStreetsData([]);
    setStreetAddressesData([]);
    if (town) {
      fetchStreets(ownerId, town.value);
      fetchTownAddresses(ownerId, town.value);
    }
  }, [fetchStreets, fetchTownAddresses, ownerId, setStreetAddressesData, setStreetsData]);

  const selectStreet = useCallback((street) => {
    setSelectedStreet(street);
    setSelectedAddress(null);
    setStreetAddressesData([]);
    if (street) {
      fetchStreetAddresses(ownerId, street.value);
    }
  }, [fetchStreetAddresses, ownerId, setStreetAddressesData]);

  const selectAddress = useCallback((address) => {
    setSelectedAddress(address);
  }, []);

  return {
    towns,
    streets,
    streetAddresses,
    townAddresses,
    selectTown,
    selectStreet,
    selectAddress,
    selectedTown,
    selectedStreet,
    selectedAddress,
  };
};

export default useUnitData;
