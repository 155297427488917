import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import uuid from 'uuid/v4';
import { PTspacing } from '../../constants/propTypes';
import Block from '../generic/Block';
import ValidationError from '../ValidationError';
import FormInput from './FormInput';
import FormLabel from './FormLabel';

const FormikInput = ({ id: givenId, label, name, marginBottom, ...props }) => {
  const id = useMemo(() => givenId || uuid(), [givenId]);
  return (
    <Block marginBottom={marginBottom}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <Field name={name}>
        {({ field }) => (
          <FormInput
            id={id}
            {...field}
            {...props}
          />
        )}
      </Field>
      <ErrorMessage name={name}>{error => <ValidationError>{error}</ValidationError>}</ErrorMessage>
    </Block>
  );
};

FormikInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  marginBottom: PTspacing,
  ...FormInput.propTypes,
};
FormikInput.defaultProps = {
  marginBottom: 3,
};

export default FormikInput;
