import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { DEVICE } from '../../../constants/breakpoints';
import { useOwnerInfo } from '../../../containers/OwnerInfoProvider';
import useDevice from '../../../utils/hooks/useDevice';
import RodzajeKontenerowMobile from './RodzajeKontenerowMobile';
import RodzajeKontenerowTablet from './RodzajeKontenerowTablet';

const RodzajeKontenerow = ({ segregations, bins }) => {
  const device = useDevice();
  const { wasteBins } = useOwnerInfo();

  const contentEntries = segregations
    .map((segregationEntry) => {
      const wasteType = segregationEntry.collectionPointCode && wasteBins[segregationEntry.collectionPointCode];
      const customBin = segregationEntry.binId && bins[segregationEntry.binId];

      const contentEntry = {
        allowed: segregationEntry.allowed,
        disallowed: segregationEntry.disallowed,
      };

      if (wasteType) {
        contentEntry.description = wasteType.name;
        contentEntry.icon = wasteType.icon;
      } else if (customBin) {
        contentEntry.description = customBin.name;
        const wasteTypeForBin = customBin.type && wasteBins[customBin.type];
        if (wasteTypeForBin) {
          contentEntry.icon = wasteTypeForBin.icon;
        }
      }

      return contentEntry;
    });

  if (device === DEVICE.mobile) {
    return <RodzajeKontenerowMobile content={contentEntries} />;
  }
  return <RodzajeKontenerowTablet content={contentEntries} />;
};
RodzajeKontenerow.propTypes = {
  segregations: PropTypes.arrayOf(PropTypes.object),
  bins: PropTypes.object,
};

export default memo(RodzajeKontenerow);
