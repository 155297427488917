import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Button from '../../../../components/Button';
import FormNotSent from '../../../../components/FormNotSent';
import FormCheckbox from '../../../../components/forms/FormCheckbox';
import FormikInput from '../../../../components/forms/FormikInput';
import FormLabel from '../../../../components/forms/FormLabel';
import FormSent from '../../../../components/FormSent';
import Block from '../../../../components/generic/Block';
import Flex from '../../../../components/generic/Flex';
import Grid from '../../../../components/generic/Grid';
import Text from '../../../../components/generic/Text';
import PageHeader from '../../../../components/PageHeader';
import Spinner from '../../../../components/Spinner';
import ValidationError from '../../../../components/ValidationError';
import { fontSize } from '../../../../constants/font';
import { px } from '../../../../utils/px';
import { su } from '../../../../utils/styledComponents';
import AdministrationNoticeHarmonogram from '../AdministrationNoticeHarmonogram';
import { useOwnerInfo } from "../../../../containers/OwnerInfoProvider";
import Link from "../../../../components/Link";
import FormikCheckbox from "../../../../components/forms/FormikCheckbox";
import { ErrorMessage } from "formik";

const BorderedBlock = styled(Block)`
  border: ${ px(1) } solid ${ props => props.theme.colorAccent };
`;

const AgreementBlock = styled(Block)`
  border-top: ${ px(1) } solid ${ props => props.theme.colorAccent };
  background-color: #F9F9F9;
`;

const NewsletterForm = ({
                          formikProps: { values, isSubmitting, handleSubmit, errors, touched, dirty, setFieldValue },
                          setSuccess,
                          success,
                          bins,
                          itemTypes,
                        }) => {
  useEffect(() => {
    if (dirty) {
      setSuccess(null);
    }
  }, [dirty, setSuccess, values]);
  const { politykaPrywatnosciURL, politykaCookiesURL, regulaminURL } = useOwnerInfo();

  return (
    <>
      <BorderedBlock as="section" marginBottom={ 2 }>
        <Block padding={ [4, `${ su(9) } ${ su(16) } ${ su(6) } ${ su(16) }`] }>
          <Block marginBottom={ 6 }>
            <PageHeader as="h2" center fontSize={ [fontSize.large, null] } marginBottom={ [4, 9] }>
              Chcesz być na bieżąco?
              <br/>
              Zamów interesujące Cię komunikaty!
            </PageHeader>
            <FormLabel bold htmlFor="email">
              Podaj adres e-mail:
            </FormLabel>
            <FormikInput id="email" name="email" placeholder="np. annazalewska@gmail.com" marginBottom={ 6 }
                         withBorder/>
            <Text bold marginBottom={ 3 }>Zaznacz interesujące Cię tematy:</Text>
            <Text>Powiadomienia o wywozie odpadów dla wpisanego adresu:</Text>
            <Grid columns={ ['1fr', '1fr 1fr'] } gap={ `${ su(2) } ${ su(4) }` } padding={ 2 } marginBottom={ 6 }>
              { bins.filter(bin => bin.type).map((bin) => {
                const checked = values.binIds[bin.id] === true;
                const onChange = (e) => {
                  setFieldValue(`binIds.${ bin.id }`, e.target.checked ? true : undefined);
                };
                return (
                  <FormCheckbox key={ bin.id } label={ bin.name && bin.name.toLowerCase() } checked={ checked }
                                onChange={ onChange }/>
                );
              }) }
            </Grid>
            <Text>Pozostałe powiadomienia:</Text>
            <Grid columns={ ['1fr', '1fr 1fr'] } gap={ 4 } padding={ 2 }>
              { itemTypes.map((itemType) => {
                const checked = values.newsletterItemTypeCodes[itemType.code] === true;
                const onChange = (e) => {
                  setFieldValue(`newsletterItemTypeCodes.${ itemType.code }`, e.target.checked ? true : undefined);
                };
                return (
                  <FormCheckbox key={ itemType.code } label={ itemType.name } checked={ checked }
                                onChange={ onChange }/>
                );
              }) }
            </Grid>
            { ((touched.binIds || touched.newsletterItemTypeCodes) && errors.selected) && (
              <ValidationError>{ errors.selected }</ValidationError>
            ) }
          </Block>
        </Block>
        <AgreementBlock>
          <Block padding={ [4, `${ su(6) } ${ su(16) } ${ su(6) } ${ su(16) }`] }>
            <Block marginBottom={ 4 }>
              <Text bold marginBottom={ 3 }>Akceptacja warunków wymagana</Text>
              <Block padding={ [2, `${ su(0) } ${ su(2) }`] }>
                <FormikCheckbox name="agreement" label={
                  <div>
                    Akceptuję warunki przedstawione w
                    { ' ' }
                    <Link inline external href={ regulaminURL }>Regulaminie</Link>
                    { ', ' }
                    <Link inline external href={ politykaPrywatnosciURL }>Polityce prywatności</Link>
                    { ' i ' }
                    <Link inline external href={ politykaCookiesURL }>Polityce cookies</Link>.
                  </div>
                }/>
                <ErrorMessage name="agreement">{ error => <ValidationError>{ error }</ValidationError> }</ErrorMessage>
              </Block>
            </Block>
            { success !== null ? (
              <>
                { success && (
                  <FormSent reset={ () => setSuccess(null) }>
                    <Text marginBottom={ 1 }>Sprawdź skrzynkę mailową, żeby potwierdzić subskrypcję.</Text>
                  </FormSent>
                ) }
                { !success && (
                  <FormNotSent retry={ handleSubmit }/>
                ) }
              </>
            ) : (
              <Flex justifyContent="center">
                <Button disabled={ isSubmitting } onClick={ handleSubmit } width={ px(205) } marginBottom={ 1 }>
                  {/* eslint-disable-next-line no-self-compare */ }
                  { isSubmitting ? (
                    <Flex>
                      <Block marginRight={ 3 }>Wysyłanie...</Block>
                      <Spinner color="#ffffff"/>
                    </Flex>
                  ) : (
                    <>Zapisz mnie</>
                  ) }
                </Button>
              </Flex>
            ) }
          </Block>
        </AgreementBlock>
      </BorderedBlock>
      <AdministrationNoticeHarmonogram marginBottom={ 2 }/>
    </>

  );
};

NewsletterForm.propTypes = {
  formikProps: PropTypes.object.isRequired,
  success: PropTypes.bool,
  setSuccess: PropTypes.func.isRequired,
  bins: PropTypes.array,
  itemTypes: PropTypes.arrayOf(PropTypes.object),
};

export default NewsletterForm;
