/* eslint-disable no-bitwise */
/**
 * @param hex - example '#000000'
 * @returns {string} - example '0,0,0'
 */
export default function hexToRgb(hex) {
  const bigint = parseInt(hex.substring(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return [r, g, b].join();
}
