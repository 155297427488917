import * as React from 'react';
import styled from 'styled-components';
import { ReactComponent as A } from '../assets/A.svg';
import { ReactComponent as Minus } from '../assets/Aminus.svg';
import { ReactComponent as Plus } from '../assets/Aplus.svg';
import Flex from '../components/generic/Flex';
import color from '../constants/color';
import { defaultBorder, defaultBorderRadius } from '../constants/style';
import { useZoom } from '../containers/ZoomProvider';
import handleEnter from '../utils/handleEnter';
import { px } from '../utils/px';

const Button = styled.button.attrs(props => ({
  tabIndex: '0',
  ...(props.onClick ? { onKeyDown: handleEnter(props.onClick) } : {}),
  ...(props.disabled ? { onClick: undefined, onKeyDown: undefined } : {}),
}))`
  width: ${px(27)};
  height: ${px(27)};
  border: ${defaultBorder};
  outline: none;
  &:focus {
    border: ${px(2)} solid ${color.link};
  }
  border-radius: ${defaultBorderRadius};
  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
`;

const AMinus = styled(Minus)`
  width: ${px(15)};
  color: ${props => props.theme.colorAccent};
`;
const APlus = styled(Plus)`
  width: ${px(15)};
  color: ${props => props.theme.colorAccent};
`;
const AImg = styled(A)`
  width: ${px(19)};
  margin-left: ${px(8)};
  margin-right: ${px(8)};
`;

const ZoomControls = () => {
  const { zoomLevel, increase, decrease } = useZoom();
  return (
    <Flex as="aside" alignItems="center" marginBottom={2} justifyContent="flex-end">
      <Button onClick={decrease} onKeyDown={handleEnter(decrease)} disabled={zoomLevel === 0}>
        <Flex height="100%" alignItems="center" justifyContent="center">
          <AMinus />
        </Flex>
      </Button>
      <AImg />
      <Button onClick={increase} onKeyDown={handleEnter(increase)}>
        <Flex height="100%" alignItems="center" justifyContent="center">
          <APlus />
        </Flex>
      </Button>
    </Flex>
  );
};

ZoomControls.propTypes = {};

export default ZoomControls;
