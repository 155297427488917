import React, { useMemo } from 'react';
import useMedia from 'react-use/lib/useMedia';
import styled from 'styled-components';
import { ReactComponent as Menu } from '../../assets/menu.svg';
import { ReactComponent as GdzieWyrzucic } from '../../assets/navigation/gdzieWyrzucic.svg';
import { ReactComponent as MojHarmonogram } from '../../assets/navigation/mojHarmonogram.svg';
import { ReactComponent as WartoWiedziec } from '../../assets/navigation/wartoWiedziec.svg';
import { ReactComponent as Zglos } from '../../assets/navigation/zglos.svg';
import { ReactComponent as ZnajdzNaMapie } from '../../assets/navigation/znajdzNaMapie.svg';
import { ReactComponent as DokumentyDlaMieszkanca } from '../../assets/navigation/dokumentyDlaMieszkanca.svg';
import MobileNavigation from '../../components/forms/MobileNavigation';
import Block from '../../components/generic/Block';
import Flex from '../../components/generic/Flex';
import Text from '../../components/generic/Text';
import { MEDIA } from '../../constants/breakpoints';
import { View } from '../../constants/enums/View';
import { fontSize, fontWeight } from '../../constants/font';
import { useNavigation } from '../../containers/NavigationProvider';
import { useOwnerInfo } from '../../containers/OwnerInfoProvider';
import { useUrlParams } from '../../containers/UrlParamsProvider';
import handleEnter from '../../utils/handleEnter';
import { px } from '../../utils/px';
import NavItem, { navItemMargin } from './NavItem';
import NavDropDownMenu from "./NavDropDownMenu";

const MenuIcon = styled(Menu)`
  display: block;
`;

const Navigation = () => {
  const { theme } = useUrlParams();
  const { views } = useOwnerInfo();
  const { activeView, goToView } = useNavigation();
  const isTablet = useMedia(MEDIA.tablet);

  const navigationOptions = useMemo(() => {
    return [
      { view: View.mojHarmonogram, title: 'Mój harmonogram', Icon: MojHarmonogram },
      { view: View.znajdzNaMapie, title: 'Znajdź na mapie', Icon: ZnajdzNaMapie },
      { view: View.gdzieWyrzucic, title: 'Gdzie wyrzucić?', Icon: GdzieWyrzucic },
      { view: View.wartoWiedziec, title: 'Warto wiedzieć', Icon: WartoWiedziec },
      { view: View.zglos, title: 'Zgłoś', Icon: Zglos },
      { view: View.dokumentyDlaMieszkanca, title: 'Dokumenty dla mieszkańca', Icon: DokumentyDlaMieszkanca },
    ].filter(opt => views.includes(opt.view));
  }, [ views ]);

  return isTablet ? (
    <nav>
      <Flex margin={ `-${ navItemMargin }` } marginBottom={ 6 } as="ul">
        { navigationOptions.slice(0, navigationOptions.length > 5 ? 4 : 5).map((option) => {
          const navigateTo = () => goToView(option.view);
          return (
            <NavItem
              key={ option.view }
              title={ option.title }
              Icon={ option.Icon }
              active={ option.view === activeView }
              onClick={ navigateTo }
              onKeyDown={ handleEnter(navigateTo) }
              tabIndex="0"
            />
          );
        }) }
        {
          navigationOptions.length > 5 &&
          <NavDropDownMenu
            items={ navigationOptions.slice(4) }
          />
        }
      </Flex>
    </nav>
  ) : (
    <nav>
      <Block marginBottom={ 3 }>
        <Flex alignItems="center" marginBottom={ 2 }>
          <Block marginRight={ 2 }>
            <MenuIcon width={ px(24) }/>
          </Block>
          <Text as="label" htmlFor="mobile-navigation" bold fontSize={ fontSize.large }>Wybierz zakładkę z menu:</Text>
        </Flex>
        <MobileNavigation
          id="mobile-navigation"
          value={ activeView }
          onChange={ view => goToView(view) }
          items={ navigationOptions.map(option => ({ value: option.view, label: option.title })) }
          inputProps={ {
            fontWeight: fontWeight.bold,
            fontSize: fontSize.semiLarge,
            color: theme.colorAccent,
            withBorder: true,
          } }
        />
      </Block>
    </nav>
  );
};

export default Navigation;
