import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ReactComponent as SearchSvg } from '../../../assets/search.svg';
import Button from '../../../components/Button';
import Flex from '../../../components/generic/Flex';
import FlexItem from '../../../components/generic/FlexItem';
import Panel from '../../../components/Panel';
import { DEVICE } from '../../../constants/breakpoints';
import { useUrlParams } from '../../../containers/UrlParamsProvider';
import { wasteSearchEngine } from '../../../utils/api';
import useDevice from '../../../utils/hooks/useDevice';
import useListData from '../../../utils/hooks/useListData';
import { px } from '../../../utils/px';
import { su } from '../../../utils/styledComponents';
import SearchBoxInput from './SearchBoxInput';

const SearchBox = ({ search }) => {
  const device = useDevice();
  const { ownerId } = useUrlParams();
  const promptFn = useMemo(() => phrase => wasteSearchEngine.prompt(ownerId, phrase), [ownerId]);
  const [{ data: suggestions }, fetchSuggestions, setSuggestions] = useListData(promptFn, []);
  const [inputValue, setInputValue] = useState('');
  const searchInputRef = useRef();
  const searchAction = useCallback(() => {
    if (inputValue) {
      search(inputValue);
    } else {
      searchInputRef.current.focus();
    }
  }, [search, inputValue]);

  const debouncedFetchSuggestions = useMemo(() => debounce(fetchSuggestions, 300), [fetchSuggestions]);

  return (
    <Panel padding={[4, `${su(6)} ${su(3)}`]} marginBottom={2}>
      <Flex>
        <FlexItem equal marginRight={3}>
          <SearchBoxInput
            placeholder="Wpisz nazwę odpadu, np. puszka"
            items={suggestions}
            value={inputValue}
            onChange={(value) => {
              setInputValue(value);
              if (value.length < 3) {
                setSuggestions([]);
              } else {
                debouncedFetchSuggestions(value);
              }
            }}
            action={search}
            inputRef={searchInputRef}
          />
        </FlexItem>
        <FlexItem shrink={0}>
          {device === DEVICE.mobile ? (
            <Button onClick={searchAction} width={px(40)} padding={px(8)}>
              <SearchSvg width={px(20)} />
            </Button>
          ) : (
            <Button width={41} onClick={searchAction}>
              Szukaj
            </Button>
          )}
        </FlexItem>
      </Flex>
    </Panel>
  );
};

SearchBox.propTypes = {
  search: PropTypes.func.isRequired,
};
export default SearchBox;
