export const View = Object.freeze({
  mojHarmonogram: 'mojHarmonogram',
  gdzieWyrzucic: 'gdzieWyrzucic',
  wartoWiedziec: 'wartoWiedziec',
  znajdzNaMapie: 'znajdzNaMapie',
  zglos: 'zglos',
  dokumentyDlaMieszkanca: 'dokumentyDlaMieszkanca'
});

export const apiTabToView = {
  TIMETABLE: View.mojHarmonogram,
  SEARCH: View.gdzieWyrzucic,
  COMPENDIUM: View.wartoWiedziec,
  MAP: View.znajdzNaMapie,
  REPORT: View.zglos,
  DOCUMENTS: View.dokumentyDlaMieszkanca
};
