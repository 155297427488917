import fromPairs from 'lodash/fromPairs';
import rawMoment from 'moment';
import { extendMoment } from 'moment-range/dist/moment-range';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import useMedia from 'react-use/lib/useMedia';
import { MEDIA } from '../../../../constants/breakpoints';
import sortAlphabetically from '../../../../utils/sortAlphabetically';
import CalendarLayoutMobile from './CalendarLayoutMobile';
import CalendarLayoutTablet from './CalendarLayoutTablet/CalendarLayoutTablet';

const moment = extendMoment(rawMoment);

function prepareMonth(monthData, binsMap) {
  const dayToBinsMap = monthData.receptions.reduce((map, reception) => {
    const receptionDate = moment(reception.date, 'YYYY-MM-DD');
    const dayOfMonth = receptionDate.date();
    const binToAdd = binsMap[reception.binId];
    if (map.has(dayOfMonth)) {
      const bins = map.get(dayOfMonth);
      bins.push(binToAdd);
      bins.sort((a, b) => sortAlphabetically(a.name, b.name));
    } else {
      map.set(dayOfMonth, [binToAdd]);
    }

    return map;
  }, new Map());

  const someDateOfMonth = moment(`${monthData.month}-01`);
  const firstDayOfMonth = someDateOfMonth.clone().startOf('month');
  const lastDayOfMonth = someDateOfMonth.clone().endOf('month');
  const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');
  const lastDayOfWeek = lastDayOfMonth.clone().endOf('week');

  const daysFromThisMonth = Array.from(moment.range(firstDayOfMonth, lastDayOfMonth).by('day'))
    .map((d) => {
      const isPaymentDay = monthData.payments.includes(d.format('YYYY-MM-DD'));
      return ({
        date: d,
        outside: false,
        bins: dayToBinsMap.get(d.date()) || [],
        ...(isPaymentDay ? { isPaymentDay: true } : null),
      });
    });
  const daysFromMonthBefore = firstDayOfWeek.isSame(firstDayOfMonth, 'day') ? [] : (
    Array.from(moment.range(firstDayOfWeek, firstDayOfMonth.clone().subtract(1, 'day')).by('day'))
      .map(d => ({ date: d, outside: true, bins: [] }))
  );
  const daysFromNextMonth = lastDayOfWeek.isSame(lastDayOfMonth, 'day') ? [] : (
    Array.from(moment.range(lastDayOfMonth.clone().add(1, 'day'), lastDayOfWeek).by('day'))
      .map(d => ({ date: d, outside: true, bins: [] }))
  );

  return [
    ...daysFromMonthBefore,
    ...daysFromThisMonth,
    ...daysFromNextMonth,
  ];
}

const Calendar = ({ monthData, bins }) => {
  const binsMap = useMemo(() => fromPairs(bins.map(bin => [bin.id, bin])), [bins]);
  const isTablet = useMedia(MEDIA.tablet);
  const days = prepareMonth(monthData, binsMap);
  return isTablet ? (
    <CalendarLayoutTablet days={days} />
  ) : (
    <CalendarLayoutMobile days={days} />
  );
};

Calendar.propTypes = {
  monthData: PropTypes.shape({
    month: PropTypes.string.isRequired,
    receptions: PropTypes.arrayOf(PropTypes.shape({
      binId: PropTypes.number,
      date: PropTypes.string.isRequired,
    })).isRequired,
    payments: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  bins: PropTypes.arrayOf(PropTypes.object),
};
export default Calendar;
