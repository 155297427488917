import PropTypes from 'prop-types';
import React from 'react';
import { followCursor } from 'tippy.js';
import paymentDay from '../../../../../assets/paymentDay.svg';
import Block from '../../../../../components/generic/Block';
import BlockImg from '../../../../../components/generic/BlockImg';
import Flex from '../../../../../components/generic/Flex';
import FlexItem from '../../../../../components/generic/FlexItem';
import Text from '../../../../../components/generic/Text';
import Tippy from '../../../../../components/Tippy';
import color from '../../../../../constants/color';
import { fontSize, fontWeight } from '../../../../../constants/font';
import { px } from '../../../../../utils/px';
import Bin from './Bin';

const Day = ({ day }) => (
  <Flex
    column
    alignItems="center"
    height="100%"
  >
    {day.isPaymentDay ? (
      <Block width="100%" marginBottom={px(2)}>
        <Tippy
          arrow={false}
          plugins={[followCursor]}
          followCursor
          duration={0}
          placement="right-end"
          content={
            <Block maxWidth={px(250)}>
              <Flex marginBottom={2}>
                <BlockImg width="19px" height="18px" src={paymentDay} alt="Termin opłaty" marginRight={px(7)} />
                <Text fontWeight={fontWeight.bold} color={color.paymentDayFont}>Termin opłaty</Text>
              </Flex>
              <Text textAlign="start">
              Opłatę za gospodarowanie odpadami komunalnymi należy uiszczać bez wezwania. Wysokość opłaty wynika ze złożonej deklaracji.
              </Text>
            </Block>
            }
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            bg={color.paymentDayBg}
            border={`1px solid ${color.paymentDayFont}`}
            paddingH={2}
          >
            <Text
              color={color.paymentDayFont}
              fontWeight={fontWeight.bold}
              fontSize={fontSize.large}
            >
              {day.date.date()}
            </Text>
            <BlockImg width="19px" height="18px" src={paymentDay} alt="Termin opłaty" />
          </Flex>
        </Tippy>
      </Block>
    ) : (
      <Flex width="100%" alignItems="center" justifyContent="space-between" paddingH={2} marginBottom={px(2)} paddingTop={px(2)}>
        <Text
          color={day.outside ? '#c0c0c5' : color.fontDefault}
          fontSize={fontSize.large}
        >
          {day.date.date()}
        </Text>
      </Flex>
    )}
    <FlexItem minHeight={16} width="95%">
      {day.bins.map((bin, index) => <Bin key={index} bin={bin} />)}
    </FlexItem>
  </Flex>
);

Day.propTypes = {
  day: PropTypes.object,
};

export default Day;
