import PropTypes from 'prop-types';
import React from 'react';
import Block from '../../../components/generic/Block';
import Text from '../../../components/generic/Text';
import { defaultBorder } from '../../../constants/style';

const NoSearchResults = ({ searchPhrase }) => (
  <Block padding={3} border={defaultBorder}>
    <Text bold marginBottom={1}>{searchPhrase}</Text>
    <Text marginBottom={4}>Nie znaleziono tego odpadu w bazie</Text>
    <Text>Baza odpadów jest na bieżąco aktualizowana. Wkrótce wyszukiwana fraza zostanie dodana do bazy. Dziękujemy!</Text>
  </Block>
);

NoSearchResults.propTypes = {
  searchPhrase: PropTypes.string.isRequired,
};
export default NoSearchResults;
