import { css } from 'styled-components';
import { BREAKPOINTS_ARRAY } from '../constants/breakpoints';
import { defaultFontSize } from '../constants/font';
import { spacingUnitInt } from '../constants/style';
import round from './round';

/**
 * These here are helper functions to more easily create styled-components
 */

/** camelCaseToDash('marginBottom') = 'margin-bottom' */
function camelCaseToDash(str) {
  return str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
}

/** ${styleCss('marginBottom')} = 'margin-bottom: ${props => props.marginBottom}' */
export const styleCss = (propName, cssPropName) =>
  props => Array.isArray(props[propName]) ? (
    css`
      ${cssPropName || camelCaseToDash(propName)}: ${props[propName][0]};
      
      ${props[propName].slice(1).map((value, index) => css`
        @media(min-width: ${BREAKPOINTS_ARRAY[index]}) {
          ${cssPropName || camelCaseToDash(propName)}: ${value};
        }
      `)};
    `
  ) : (
    props[propName] && css`
      ${cssPropName || camelCaseToDash(propName)}: ${props[propName]};
    `
  );

/** Same as styleCss but, if the value is a number, it is treated as spacingUnits, rather then exact value */
export const styleCssSpacing = (propName, cssPropName) =>
  props => Array.isArray(props[propName]) ? (
    css`
      ${cssPropName || camelCaseToDash(propName)}: ${su(props[propName][0])};
      
      ${props[propName].slice(1).map((value, index) => css`
        @media(min-width: ${BREAKPOINTS_ARRAY[index]}) {
          ${cssPropName || camelCaseToDash(propName)}: ${su(value)};
        }
      `)};
    `
  ) : (
    hasValueProvided(props[propName]) && css`
      ${cssPropName || camelCaseToDash(propName)}: ${su(props[propName])};
    `
  );

/** su stands for for spacing units */
export const su = (value) => {
  if (typeof value === 'number') {
    return `${round(value * spacingUnitInt / defaultFontSize, 3)}rem`;
  }
  return value;
};

const hasValueProvided = value => value || value === 0;
