import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PTspacing } from '../../constants/propTypes';
import { styleCss, styleCssSpacing, su } from '../../utils/styledComponents';

const TableHeader = styled.th`
  padding: ${su(1)} ${su(2)};
  background-color: #36354d;
  
  ${styleCssSpacing('width')};
  ${styleCss('textAlign')};
  
  text-transform: uppercase;
  color: #ffffff;
`;
TableHeader.propTypes = {
  width: PTspacing,
  textAlign: PropTypes.string,
};

export default TableHeader;
