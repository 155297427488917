import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PTchildren } from '../constants/propTypes';
import { useOwnerInfo } from './OwnerInfoProvider';

const MapFiltersContext = React.createContext();

const getMapBinCodes = wasteBins => Object.values(wasteBins)
  .filter(bin => bin.showOnMap)
  .map(bin => bin.code);

export const MapFiltersProvider = ({ children }) => {
  const { wasteBins } = useOwnerInfo();
  const mapBinCodes = useMemo(() => getMapBinCodes(wasteBins), [wasteBins]);
  const [mapFilters, setMapFilters] = useState(mapBinCodes);
  useEffect(() => {
    setMapFilters(mapBinCodes);
  }, [mapBinCodes]);

  const toggleAll = useCallback(() => {
    if (mapFilters.length === mapBinCodes.length) {
      setMapFilters([]);
    } else {
      setMapFilters(mapBinCodes);
    }
  }, [mapBinCodes, mapFilters.length]);

  const toggleOne = useCallback((idToToggle) => {
    if (mapFilters.includes(idToToggle)) {
      setMapFilters(prevMapFilters => prevMapFilters.filter(id => id !== idToToggle));
    } else {
      setMapFilters(prevMapFilters => [...prevMapFilters, idToToggle]);
    }
  }, [mapFilters]);

  const value = useMemo(() => ({ mapFilters, setMapFilters, toggleAll, toggleOne }), [mapFilters, toggleAll, toggleOne]);
  return (
    <MapFiltersContext.Provider value={value}>
      {children}
    </MapFiltersContext.Provider>
  );
};

MapFiltersProvider.propTypes = {
  children: PTchildren,
};

export const useMapFilters = () => {
  const context = useContext(MapFiltersContext);
  if (context === undefined) {
    throw new Error('useMapFiltersProvider must be used  together with MapFiltersProvider');
  }
  return context;
};
