import PropTypes from 'prop-types';

export const PTchildren = PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]);

export const PTspacing = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
  PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ),
]);

export const PTresponsive = propType => PropTypes.oneOfType([
  propType,
  PropTypes.arrayOf(propType),
]);
