import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PTresponsive, PTspacing } from '../../constants/propTypes';
import { styleCss, styleCssSpacing } from '../../utils/styledComponents';
import Block from './Block';

const Grid = styled(Block)`
  display: grid;

  ${styleCss('rows', 'grid-template-rows')};
  ${styleCss('columns', 'grid-template-columns')};
  
  ${styleCss('autoRows', 'grid-auto-rows')};
  ${styleCss('autoColumns', 'grid-auto-columns')};
  
  ${styleCssSpacing('rowGap', 'grid-row-gap')};
  ${styleCssSpacing('columnGap', 'grid-column-gap')};
  ${styleCssSpacing('gap', 'grid-gap')}; // row, col
  
  ${styleCss('justifyItems', 'justify-items')};
  ${styleCss('alignItems', 'align-items')};
  
  ${styleCss('justifyContent', 'justify-content')};
  ${styleCss('alignContent', 'align-content')};
  
  ${styleCss('flow', 'grid-auto-flow')};
`;

Grid.propTypes = {
  ...Block.propTypes,
  rows: PTresponsive(PropTypes.string),
  columns: PTresponsive(PropTypes.string),
  autoRows: PTresponsive(PropTypes.string),
  autoColumns: PTresponsive(PropTypes.string),
  rowGap: PTspacing,
  columnGap: PTspacing,
  gap: PTspacing,
  justifyItems: PTresponsive(PropTypes.oneOf(['start', 'end', 'center', 'stretch'])),
  alignItems: PTresponsive(PropTypes.oneOf(['start', 'end', 'center', 'stretch'])),
  justifyContent: PTresponsive(PropTypes.oneOf(['start', 'end', 'center', 'stretch', 'space-around', 'space-between', 'space-evenly'])),
  alignContent: PTresponsive(PropTypes.oneOf(['start', 'end', 'center', 'stretch', 'space-around', 'space-between', 'space-evenly'])),
  flow: PTresponsive(PropTypes.oneOf(['row', 'column', 'row dense', 'column dense'])),
};

export default Grid;
