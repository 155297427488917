import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { PTspacing } from '../constants/propTypes';
import { px } from '../utils/px';
import { styleCssSpacing } from '../utils/styledComponents';

const loaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border-left: ${px(2)} solid ${props => props.color || props.theme.colorAccent};
  border-top: ${px(2)} solid transparent;
  animation: ${loaderAnimation} 1100ms infinite linear;
  border-radius: 100%;
  width: ${props => props.width};
  ${styleCssSpacing('width')};
  ${styleCssSpacing('width', 'height')};
`;
Spinner.defaultProps = {
  width: 3,
};
Spinner.propTypes = {
  width: PTspacing,
  color: PropTypes.string,
};
export default Spinner;
