import * as yup from 'yup';
import getCheckedValuesFromObject from '../../../../utils/getCheckedValuesFromObject';
import { emailSchema, agreementSchema } from '../../../../utils/validationSchema';


export const initialValues = {
  email: '',
  binIds: {},
  newsletterItemTypeCodes: {},
  agreement: false,
};

export const validationSchema = yup.object({
  email: emailSchema,
  selected: yup.mixed().test('selected', 'Zaznacz przynajmniej jeden temat', function () {
    const { parent } = this;
    return [
      ...getCheckedValuesFromObject(parent.binIds),
      ...getCheckedValuesFromObject(parent.newsletterItemTypeCodes),
    ].length > 0;
  }),
  agreement: agreementSchema
});
