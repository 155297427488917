import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import Block from '../../../../components/generic/Block';
import Flex from '../../../../components/generic/Flex';
import FlexItem from '../../../../components/generic/FlexItem';
import Text from '../../../../components/generic/Text';
import Spinner from '../../../../components/Spinner';
import color from '../../../../constants/color';
import useTimetableData from '../../useTimetableData';
import AddressPanel from './AddressPanel';
import Calendar from './Calendar';
import MonthSelector from './MonthSelector';
import DownloadDropDown from './DownloadDropDown'

const Timetable = ({ unitId, textAddress, onChangeAddress }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { timetable, bins, paymentPeriods, pending, error } = useTimetableData(unitId);
  const monthsData = useMemo(() => {
    if (!timetable || !paymentPeriods) {
      return [];
    }

    const monthMap = new Map();
    // dane o wywozach
    timetable.forEach((timetableEntry) => {
      monthMap.set(timetableEntry.month, {
        month: timetableEntry.month,
        receptions: timetableEntry.receptions,
        payments: [],
      });
    });
    // AWW-26 chcemy wyświetlać do 4 miesięcy z opłatami nawet jeśli nie ma wywozów
    const currentAndFuturePaymentPeriods = paymentPeriods
      .map((dateString) => {
        const yyyymmdd = /^(\d{4}-\d{2}-\d{2})/.exec(dateString)[0]; // pominięcie timezone na wypadek jego różnicy FE/BE
        return moment(yyyymmdd);
      })
      .filter((d) => {
        const now = moment();
        return now.isSame(d, 'month') || now.isBefore(d, 'month'); // tylko ten miesiąc i przyszłe
      })
      .map(d => d.format('YYYY-MM-DD'));
    const firstFour = currentAndFuturePaymentPeriods.slice(0, 4);
    firstFour.forEach((paymentDate) => {
      const monthKey = moment(paymentDate).format('YYYY-MM');
      // uzupełnienie wpisu, lub stworzenie nowego
      const entry = monthMap.get(monthKey) || { month: monthKey, receptions: [], payments: [] };
      const newEntry = { ...entry, payments: [...entry.payments, paymentDate] };
      monthMap.set(monthKey, newEntry);
    });
    // uzupełnienie reszty opłat TYLKO do tych miesięcy z wywozami
    const rest = currentAndFuturePaymentPeriods.slice(4);
    rest.forEach((paymentDate) => {
      const monthKey = moment(paymentDate).format('YYYY-MM');
      const entry = monthMap.get(monthKey);
      if (entry) {
        const newEntry = { ...entry, payments: [...entry.payments, paymentDate] };
        monthMap.set(monthKey, newEntry);
      }
    });

    return Array.from(monthMap.keys()).sort().map(key => monthMap.get(key));
  }, [paymentPeriods, timetable]);

  if (pending) {
    return (
      <Flex justifyContent="center" alignItems="center" marginBottom={ 6 }>
        <Spinner width={ 12 }/>
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex column alignItems="center" marginBottom={ 6 }>
        <Text color={ color.validationError }>Wystąpił problem z pobraniem harmonogramu</Text>
        <Text inline color={ color.link } onClick={ onChangeAddress }>
          Zmień adres
        </Text>
      </Flex>
    );
  }

  const available = monthsData.length > 0 && !!bins;
  return (
    <>
      <AddressPanel textAddress={ textAddress } onChangeAddress={ onChangeAddress } available={ available }/>
      { available && (
        <Block marginBottom={ 10 }>
          <Block marginBottom={ [4, 1] }>
            <Flex justifyContent="space-between" alignItems="center" flexWrap marginV={ -1 }>
              <FlexItem shrink={ 0 } marginV={ 1 }>
                <MonthSelector
                  options={ monthsData.map((monthEntry, index) => ({ value: index, label: monthEntry.month })) }
                  selectedIndex={ selectedIndex }
                  setSelectedIndex={ setSelectedIndex }
                />
              </FlexItem>
              <FlexItem shrink={ 0 } marginV={ 1 }>
                <DownloadDropDown unitId={ unitId }/>
              </FlexItem>
            </Flex>
          </Block>
          <Block marginBottom={ 3 }>
            <Calendar monthData={ monthsData[selectedIndex] } bins={ bins }/>
          </Block>
        </Block>
      ) }
    </>
  );
};

Timetable.propTypes = {
  unitId: PropTypes.string.isRequired,
  textAddress: PropTypes.string.isRequired,
  onChangeAddress: PropTypes.func.isRequired,
};
export default Timetable;
