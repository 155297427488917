import React from 'react';
import segrego from '../assets/SegreGO_RGB-02.png';
import segrego2x from '../assets/SegreGO_RGB-02@2x.png';
import segrego3x from '../assets/SegreGO_RGB-02@3x.png';
import BlockImg from '../components/generic/BlockImg';
import Flex from '../components/generic/Flex';
import Text from '../components/generic/Text';
import Link from '../components/Link';
import VerticalSeparator from '../components/VerticalSeparator';
import { fontSize } from '../constants/font';
import { useOwnerInfo } from '../containers/OwnerInfoProvider';
import { px } from '../utils/px';
import srcSet from '../utils/srcSet';

const Footer = () => {
  const { politykaPrywatnosciURL, politykaCookiesURL, regulaminURL } = useOwnerInfo();

  return (
    <footer>
      <Flex flexWrap justifyContent="space-between" marginBottom={px(30)}>
        <Flex width={['100%', 'auto']} flexWrap as="ul" alignItems="center" marginRight={2} marginBottom={[1, 0]}>
          {(politykaPrywatnosciURL && regulaminURL && politykaCookiesURL) && (
            <>
              <Flex as="li">
                <Link external fontSize={fontSize.small} href={politykaPrywatnosciURL}>Polityka prywatności</Link>
                <VerticalSeparator />
              </Flex>
              <Flex as="li">
                <Link external fontSize={fontSize.small} href={regulaminURL}>Regulamin</Link>
                <VerticalSeparator />
              </Flex>
              <Flex as="li">
                <Link external fontSize={fontSize.small} href={politykaCookiesURL}>Polityka Cookies</Link>
              </Flex>
            </>
          )}
        </Flex>
        <Flex width={['100%', 'auto']} alignItems="center">
          <Text fontSize={fontSize.small}>Utworzone przez&nbsp;</Text>
          <Link external fontSize={fontSize.small} href="https://ammsystems.pl/">AMM Systems sp. z o.o.</Link>
        </Flex>
      </Flex>
      <Flex justifyContent="center">
        <Link external href="https://segrego.pl/">
          <BlockImg src={segrego} srcSet={srcSet(segrego2x, segrego3x)} width={px(156)} alt="SegreGO" />
        </Link>
      </Flex>
    </footer>
  );
};

export default Footer;
