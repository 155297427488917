import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import color from '../constants/color';
import { fontFamilyAlternative, fontWeight } from '../constants/font';
import { PTchildren, PTspacing } from '../constants/propTypes';
import { defaultBorderRadius } from '../constants/style';
import handleEnter from '../utils/handleEnter';
import hexToRgb from '../utils/hexToRgb';
import { px } from '../utils/px';
import { styleCssSpacing } from '../utils/styledComponents';

const Button = styled.button.attrs(props => ({
  tabIndex: '0',
  ...(props.onClick ? { onKeyDown: handleEnter(props.onClick) } : {}),
  ...(props.disabled ? { onClick: undefined, onKeyDown: undefined } : {}),
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${px(40)};
  padding: ${px(12)} ${px(35)};
  ${props => props.small && css`
    height: ${px(30)};
    padding: ${px(7)} ${px(10)};
  `};
  border-radius: ${defaultBorderRadius};
  border: none;
  
  color: ${color.buttonFont};
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamilyAlternative};
  line-height: 1;
  
  cursor: pointer;
  background-color: ${props => props.theme.colorButtonBg};
  ${props => !props.disabled && css`
    &:hover {
      background-color: ${props.theme.colorButtonBgHover};
    }
  `};
  ${props => props.disabled && css`
    background-color: rgba(${hexToRgb(props.theme.colorButtonBg)}, 0.5);
    cursor: auto;  
  `};
  
  ${styleCssSpacing('width')};
  ${styleCssSpacing('margin')};
  ${styleCssSpacing('marginBottom')};
  ${styleCssSpacing('marginRight')};
  ${styleCssSpacing('padding')};
`;

Button.propTypes = {
  children: PTchildren,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  width: PTspacing,
  margin: PTspacing,
  marginBottom: PTspacing,
  marginRight: PTspacing,
  padding: PTspacing,
};

export default Button;
