const color = {
  pageBackground: '#ffffff',
  fontDefault: '#302f40',
  navItem: '#69687e',
  border: '#e3e3e9',
  navBgHover: '#f3f3f3',
  buttonFont: '#ffffff',
  panelBg: '#f3f3f3',
  panelBgHover: '#707070',
  link: '#2556db',
  wasteSpecial: '#6161fa',
  paymentDayFont: '#478500',
  paymentDayBg: 'rgba(51, 164, 81, 0.2)',
  throwAllowed: '#57ba27',
  throwDisallowed: '#d53540',
  divider: '#dbdbdd',
  inputPlaceholder: '#6e6d79',
  validationError: '#ca3f4a',
  formSent: '#478500',
  formNotSent: '#d53540',
  tableBorder: '#dfdff1',
  bannerBg: '#006793',
  bannerBg2: '#4690b0',
};

export default color;
