import PropTypes from 'prop-types';
import React from 'react';
import Block from '../../../components/generic/Block';
import BlockImg from '../../../components/generic/BlockImg';
import Flex from '../../../components/generic/Flex';
import FlexItem from '../../../components/generic/FlexItem';
import Text from '../../../components/generic/Text';
import Panel from '../../../components/Panel';
import color from '../../../constants/color';
import { defaultBorder } from '../../../constants/style';
import { px } from '../../../utils/px';

const RodzajeKontenerowMobile = ({ content }) => (
  <Panel padding={2}>
    <Text bold marginBottom={2}>POJEMNIKI</Text>
    {content.map(({ icon, description, allowed, disallowed }, index) => (
      <Block marginBottom={1} padding={2} key={index} bg="#ffffff" border={defaultBorder}>
        <Flex alignItems="center" marginBottom={3}>
          <FlexItem shrink={0}>
            {icon && <BlockImg src={icon} alt="" width={px(40)} marginRight={2} />}
          </FlexItem>
          <FlexItem equal>
            <Text bold>{description}</Text>
          </FlexItem>
        </Flex>
        <Text bold color={color.throwAllowed} marginBottom={1}>CO WRZUCAĆ?</Text>
        <Block as="ul" marginBottom={3}>
          {allowed.map((allowedEntry, okIndex, array) => (
            <li key={allowedEntry} style={{ display: 'inline' }}>{allowedEntry}{okIndex < array.length - 1 ? ', ' : null}</li>
          ))}
        </Block>
        <Text bold color={color.throwDisallowed} marginBottom={1}>CZEGO NIE WRZUCAĆ?</Text>
        <Block as="ul">
          {disallowed.map((disallowedEntry, notOkIndex, array) => (
            <li
              key={disallowedEntry}
              style={{ display: 'inline' }}
              color={color.throwDisallowed}
            >
              {disallowedEntry}{notOkIndex < array.length - 1 ? ', ' : null}
            </li>
          ))}
        </Block>
      </Block>
    ))}
  </Panel>
);
RodzajeKontenerowMobile.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RodzajeKontenerowMobile;
