import styled from 'styled-components';
import { ReactComponent as SelectArrow } from '../../assets/select-arrow.svg';
import color from '../../constants/color';
import { px } from '../../utils/px';

const ArrowIcon = styled(SelectArrow)`
  color: ${color.navItem};
  width: ${px(11)};
`;

export default ArrowIcon;
