import React from 'react';
import color from '../constants/color';
import { PTchildren } from '../constants/propTypes';
import Text from './generic/Text';

const ValidationError = ({ children }) => (
  <Text color={color.validationError}>{children}</Text>
);

ValidationError.propTypes = {
  children: PTchildren,
};

export default ValidationError;
