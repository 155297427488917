import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import color from '../../constants/color';
import { PTspacing } from '../../constants/propTypes';
import { defaultBorderRadius } from '../../constants/style';
import { px } from '../../utils/px';
import { styleCss, styleCssSpacing, su } from '../../utils/styledComponents';

const FormInput = styled.input`
  display: block;
  width: 100%;
  height: ${px(40)};
  ${props => props.small && css`
    height: ${px(34)};    
  `};
  padding: ${su(1)} ${su(3)};
  ${props => props.loading && css`
    padding-right: ${su(5)};
  `};
  border-radius: ${defaultBorderRadius};
  outline: none;
  border: ${props => px(props.theme.dimensionInputActiveBorder)} solid transparent;
  ${props => props.withBorder && css`
    border: ${px(1)} solid ${color.border};
  `};
  &:focus {
    border: ${props => px(props.theme.dimensionInputActiveBorder)} solid ${props => props.theme.colorInputActive};
  }
  ${props => props.active && css`
    border: ${px(props.theme.dimensionInputActiveBorder)} solid ${props.theme.colorInputActive};
  `};
  ::placeholder {
    color: ${color.inputPlaceholder};
  }
  &:disabled {
    border: ${px(1)} solid #a2a2b6;
    background: transparent;
  }
  
  ${styleCssSpacing('marginBottom')};
  ${styleCss('fontSize')};
  ${styleCss('fontWeight')};
  ${styleCss('color')};
`;
FormInput.defaultProps = {
  color: color.fontDefault,
};
FormInput.propTypes = {
  withBorder: PropTypes.bool,
  small: PropTypes.bool,
  marginBottom: PTspacing,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.number,
  color: PropTypes.string,
};

export default FormInput;
