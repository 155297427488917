const sortAlphabetically = (a, b, reverse = false) => {
  const reverseMultiplier = reverse ? -1 : 1;
  if (a > b) {
    return reverseMultiplier;
  }
  if (a < b) {
    return -1 * reverseMultiplier;
  }
  return 0;
};

export default sortAlphabetically;
