import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as ArrowLeft } from '../../../../assets/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/arrow-right.svg';
import Select from '../../../../components/forms/Select';
import Block from '../../../../components/generic/Block';
import Flex from '../../../../components/generic/Flex';
import FlexItem from '../../../../components/generic/FlexItem';
import RoundArrowButton from '../../../../components/RoundArrowButton';
import { fontSize, fontWeight } from '../../../../constants/font';
import handleEnter from '../../../../utils/handleEnter';
import { px } from '../../../../utils/px';

const MonthSelector = ({ options, selectedIndex, setSelectedIndex }) => {
  const handleLeftClick = () => setSelectedIndex((selectedIndex + options.length - 1) % options.length);
  const handleRightClick = () => setSelectedIndex((selectedIndex + 1) % options.length);

  return (
    <Flex alignItems="center" width={[px(300), px(400)]}>
      <FlexItem equal marginRight={[3, 4]}>
        <Select
          value={options[selectedIndex].value}
          items={options}
          onChange={selected => setSelectedIndex(selected)}
          ariaLabel="Miesiąc"
          withBorder
          small
          inputProps={{
            fontSize: fontSize.semiLarge,
            fontWeight: fontWeight.bold,
            withBorder: true,
            small: true,
          }}
        />
      </FlexItem>
      <FlexItem minWidth={0} shrink={0} marginRight={[3, 4]}>
        <Flex>
          <Block marginRight={[2, 1]}>
            <RoundArrowButton
              withBorder
              invertOnHover
              tabIndex="0"
              onClick={handleLeftClick}
              onKeyDown={handleEnter(handleLeftClick)}
              icon={ArrowLeft}
            />
          </Block>
          <Block>
            <RoundArrowButton
              withBorder
              invertOnHover
              tabIndex="0"
              onClick={handleRightClick}
              onKeyDown={handleEnter(handleRightClick)}
              icon={ArrowRight}
            />
          </Block>
        </Flex>
      </FlexItem>
    </Flex>
  );
};

MonthSelector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  selectedIndex: PropTypes.number,
  setSelectedIndex: PropTypes.func.isRequired,
};
export default MonthSelector;
