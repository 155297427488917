import React from 'react';
import Grid from '../../../components/generic/Grid';
import Panel from '../../../components/Panel';
import PokazNaMapie from '../../../components/PokazNaMapie';
import { DEVICE } from '../../../constants/breakpoints';
import WasteTypeId from '../../../constants/enums/WasteTypeId';
import useDevice from '../../../utils/hooks/useDevice';

const Lekarstwa = () => {
  const device = useDevice();
  return (
    <Panel paddingLeft={15}>
      <Grid gap={2} flow={device === DEVICE.mobile ? 'row' : 'column'} justifyContent="space-between">
        <div>
          Lekarstwa powinny być oddawane do oznakowanych pojemników w wybranych Aptekach.
        </div>
        <div>
          <PokazNaMapie wasteTypes={[WasteTypeId.apteka]} />
        </div>
      </Grid>
    </Panel>
  );
};

Lekarstwa.propTypes = {};

export default Lekarstwa;
