import React, { useCallback, useContext, useMemo } from 'react';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import { LS_ZOOM } from '../constants/localStorage';
import { PTchildren } from '../constants/propTypes';
import { useUrlParams } from './UrlParamsProvider';

const ZoomContext = React.createContext();

export const ZoomProvider = ({ children }) => {
  const { ownerId, hideZoom } = useUrlParams();
  //raw number would become a string in local storage, hance an object usage here
  const [zoomLevel, setZoomLevel] = useLocalStorage(`${LS_ZOOM}_${ownerId}`, { zoom: 0 });

  const increase = useCallback(() => {
    setZoomLevel(prevZoom => ({ zoom: prevZoom.zoom + 1 }));
  }, [setZoomLevel]);

  const decrease = useCallback(() => {
    setZoomLevel(prevZoom => ({ zoom: Math.max(0, prevZoom.zoom - 1) }));
  }, [setZoomLevel]);

  const value = useMemo(
    () => ({ zoomLevel: hideZoom ? 0 : zoomLevel.zoom, increase, decrease }),
    [hideZoom, zoomLevel.zoom, increase, decrease],
  );

  return (
    <ZoomContext.Provider value={value}>
      {children}
    </ZoomContext.Provider>
  );
};

ZoomProvider.propTypes = {
  children: PTchildren,
};

export const useZoom = () => {
  const context = useContext(ZoomContext);
  if (context === undefined) {
    throw new Error('useZoom must be used  together with ZoomProvider');
  }
  return context;
};
