export const ReportType = {
  UNCOLLECTED_WASTE: 'uncollected-waste',
  WILD_DUMP: 'wild-dump',
  SMOKE: 'smoke',
  ORDER_CONTAINER: 'order-container',
  ORDER_TRASHBAG: 'order-trashbag',
  ORDER_BIN: 'order-bin',
  OTHER: 'other',
};

export const REPORT_TYPE_OPTIONS = [
  { value: ReportType.UNCOLLECTED_WASTE, label: 'Nieodebrane odpady' },
  { value: ReportType.WILD_DUMP, label: 'Dzikie wysypisko' },
  { value: ReportType.SMOKE, label: 'Zgłoś kopciucha' },
  { value: ReportType.ORDER_CONTAINER, label: 'Zamów kontener' },
  { value: ReportType.ORDER_TRASHBAG, label: 'Zamów worek' },
  { value: ReportType.ORDER_BIN, label: 'Zamów kubeł' },
  { value: ReportType.OTHER, label: 'Inne' },
];

// eslint-disable-next-line max-len
export const AGREEMENT = 'Wyrażam zgodę na przetwarzanie moich danych osobowych w celu przesłania zgłoszenia, podjęcia działań niezbędnych do obsługi zgłoszenia oraz przesłania mi informacji na mój adres poczty elektronicznej, w tym także na moje telekomunikacyjne urządzenie końcowe.';
