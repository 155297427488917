import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { PTresponsive, PTspacing } from '../../constants/propTypes';
import { styleCss, styleCssSpacing } from '../../utils/styledComponents';

export const spaceProps = css`
  ${styleCssSpacing('height')};
  ${styleCssSpacing('minHeight')};
  ${styleCssSpacing('maxHeight')};
  ${styleCssSpacing('width')};
  ${styleCssSpacing('minWidth')};
  ${styleCssSpacing('maxWidth')};
  
  ${styleCssSpacing('margin')};
  ${styleCssSpacing('marginTop')};
  ${styleCssSpacing('marginBottom')};
  ${styleCssSpacing('marginLeft')};
  ${styleCssSpacing('marginRight')};
  ${styleCssSpacing('marginH', 'margin-left')};
  ${styleCssSpacing('marginH', 'margin-right')};
  ${styleCssSpacing('marginV', 'margin-top')};
  ${styleCssSpacing('marginV', 'margin-bottom')};
  
  ${styleCssSpacing('padding')};
  ${styleCssSpacing('paddingTop')};
  ${styleCssSpacing('paddingBottom')};
  ${styleCssSpacing('paddingLeft')};
  ${styleCssSpacing('paddingRight')};
  ${styleCssSpacing('paddingH', 'padding-left')};
  ${styleCssSpacing('paddingH', 'padding-right')};
  ${styleCssSpacing('paddingV', 'padding-top')};
  ${styleCssSpacing('paddingV', 'padding-bottom')};
`;

export const spacePropTypes = {
  height: PTspacing,
  minHeight: PTspacing,
  maxHeight: PTspacing,
  width: PTspacing,
  maxWidth: PTspacing,
  margin: PTspacing,
  marginTop: PTspacing,
  marginBottom: PTspacing,
  marginLeft: PTspacing,
  marginRight: PTspacing,
  marginH: PTspacing,
  marginV: PTspacing,
  padding: PTspacing,
  paddingTop: PTspacing,
  paddingBottom: PTspacing,
  paddingLeft: PTspacing,
  paddingRight: PTspacing,
  paddingH: PTspacing,
  paddingV: PTspacing,
};

const Block = styled.div`
  display: block; //setting this explicite helps with extending or usage with "as" attribute
  ${spaceProps};
  ${styleCss('bg', 'background')};
  ${styleCss('border')};
  
  ${props => props.relative && css`
    position: relative;
  `};
  
  ${props => props.onClick && css`
    cursor: pointer;
  `};
`;
Block.propTypes = {
  ...spacePropTypes,
  bg: PTresponsive(PropTypes.string),
  border: PTresponsive(PropTypes.string),
  relative: PropTypes.bool,
};

export default Block;
