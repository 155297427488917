/* eslint-disable no-prototype-builtins */
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import ControllerButton from '../../../components/autocomplete/ControllerButton';
import Deselect from '../../../components/autocomplete/Deselect';
import Item from '../../../components/autocomplete/Item';
import ItemsList from '../../../components/autocomplete/ItemsList';
import FormInput from '../../../components/forms/FormInput';
import Block from '../../../components/generic/Block';
import SrOnly from '../../../components/SrOnly';

const SearchBoxInput = ({ placeholder, items, value, onChange, action, inputRef }) => {
  const handleStateChange = useCallback((changes) => {
    if (changes.hasOwnProperty('selectedItem')) {
      onChange(changes.selectedItem || '');
      if (changes.selectedItem) {
        action(changes.selectedItem);
      }
    } else if (changes.hasOwnProperty('inputValue')) {
      onChange(changes.inputValue);
    }
  }, [action, onChange]);

  return (
    <Downshift
      selectedItem={value}
      itemToString={item => item || ''}
      onStateChange={handleStateChange}
    >
      {({
        getRootProps,
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        getToggleButtonProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
        toggleMenu,
        clearSelection,
      }) => (
        <Block relative {...getRootProps()}>
          <Block relative>
            <SrOnly>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
              <label {...getLabelProps()}>Pole wyszukiwania</label>
            </SrOnly>
            <FormInput {...getInputProps({
              autoFocus: true,
              ref: inputRef,
              placeholder,
              active: isOpen,
              onFocus: () => {
                if (items.length > 0 && !isOpen) {
                  toggleMenu();
                }
              },
              onChange: ((e) => {
                if ((items.length > 0 && !isOpen) || (items.length === 0 && isOpen)) {
                  toggleMenu();
                }
                if (e.target.value === '') {
                  clearSelection();
                }
              }),
              onKeyDown: (e) => {
                if (e.key === 'Enter' && !highlightedIndex) {
                  action(inputValue);
                  if (isOpen) {
                    toggleMenu();
                  }
                }
              },
            })}
            />
            {selectedItem ? (
              <ControllerButton {...getToggleButtonProps()}>
                <Deselect onClick={clearSelection} />
              </ControllerButton>
            ) : null}
          </Block>
          <ItemsList {...getMenuProps({ isOpen: isOpen && items.length > 0 })}>
            {(isOpen && items.length > 0)
              ? items
                .filter(item => !inputValue || item.toLowerCase().includes(inputValue.toLowerCase()))
                .map((item, index) => (
                  <Item
                    {...getItemProps({
                      key: item,
                      item,
                      highlighted: highlightedIndex === index,
                      selected: selectedItem === item,
                    })}
                  >
                    {item}
                  </Item>
                ))
              : null}
          </ItemsList>
        </Block>
      )}
    </Downshift>
  );
};
SearchBoxInput.propTypes = {
  placeholder: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  inputRef: PropTypes.object,
};
export default SearchBoxInput;
