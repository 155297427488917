import React from 'react';
import BlockImg from '../../../components/generic/BlockImg';
import Flex from '../../../components/generic/Flex';
import Panel from '../../../components/Panel';
import Paragraph from '../../../components/Paragraph';
import PokazNaMapie from '../../../components/PokazNaMapie';
import WasteTypeId from '../../../constants/enums/WasteTypeId';
import przekreslonyKontener from '../OznakowanieNaOdpadach/assets/przekreslony-kontener.svg';

const Elektroodpady = () => (
  <Panel paddingLeft={15}>
    <Paragraph>
      Elektroodpady to grupa odpadów, w której skład wchodzi zużyty sprzęt elektroniczny i elektryczny. Zabronione jest wyrzucanie elektroodpadów razem z innymi
      odpadami. Za nieodpowiednie pozbycie się elektroodpadów grozi grzywna do 5 tys zł. Elektroodpady zawierają niebezpieczne substancje szkodliwe i trujące:
      Rtęć, Ołów, Chrom, Kadm, Nikiel, Azbest
    </Paragraph>
    <Paragraph>
      Wszelkie odpady oznaczone tym znakiem muszą zostać oddane do Punktu Selektywnej Zbiórki Odpadów Komunalnych lub w miejscu zakupu nowego sprzętu - sprzęt
      taki jak pralki, lodówki, kuchenki jest odbierany za darmo w sklepie, gdy kupujesz podobny sprzęt. Zasada ta obowiązuje również przy zakupach przez
      Internet. Warunkiem oddania zużytego sprzętu jest jego kompletność.
    </Paragraph>
    <Flex justifyContent="center" marginBottom={2}>
      <BlockImg width={8} src={przekreslonyKontener} alt="Przekreślony kontener" />
    </Flex>
    <Paragraph>
      Żarówki, baterie oraz drobne odpady elektryczne i elektroniczne (do 25cm) można oddać w sklepach RTV AGD lub PSZOK-ach
    </Paragraph>
    <PokazNaMapie wasteTypes={[WasteTypeId.baterie, WasteTypeId.pszok]} />
  </Panel>
);

Elektroodpady.propTypes = {};

export default Elektroodpady;
