import styled from 'styled-components';
import { px } from '../utils/px';
import { su } from '../utils/styledComponents';

const VerticalSeparator = styled.div`
  align-self: stretch;
  flex-shrink: 0;
  border-right: ${px(1)} solid #DFDEE0;
  margin-right: ${su(2)};
  margin-left: ${su(2)};
`;

export default VerticalSeparator;
