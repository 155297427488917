/* eslint-disable max-len */
import React from 'react';
import { ReactComponent as BigContainer } from '../../assets/wartoWiedziec/bigContainer.svg';
import { ReactComponent as BoxSigns } from '../../assets/wartoWiedziec/boxSigns.svg';
import { ReactComponent as ContainerTypes } from '../../assets/wartoWiedziec/containerTypes.svg';
import { ReactComponent as Curiosities } from '../../assets/wartoWiedziec/curiosities.svg';
import { ReactComponent as Electric } from '../../assets/wartoWiedziec/electric.svg';
import { ReactComponent as Medicines } from '../../assets/wartoWiedziec/medicines.svg';
import { ReactComponent as Pszok } from '../../assets/wartoWiedziec/pszok.svg';
import WasteTypeId from '../../constants/enums/WasteTypeId';
import Ciekawostki from './Ciekawostki/Ciekawostki';
import Elektroodpady from './Elektroodpady/Elektroodpady';
import Lekarstwa from './Lekarstwa/Lekarstwa';
import OdpadyWielkogabarytowe from './OdpadyWielkogabarytowe/OdpadyWielkogabarytowe';
import OznakowaniaNaOpakowaniach from './OznakowanieNaOdpadach/OznakowaniaNaOpakowaniach';
import PunktSelektywnejZbiorki from './PunktSelektywnejZbiorki/PunktSelektywnejZbiorki';
import RodzajeKontenerow from './RodzajeKontenerow/RodzajeKontenerow';


export default (code, title, description) => {
    return map[code] && {
        ...map[code],
        title,
        description
    };
}

const map = {
    'pszok': {
        Icon: Pszok,
        renderContent: ({ segregations, pszoks }) => {
            const pszokSegregation = segregations.filter(segregationEntry =>
                segregationEntry.collectionPointCode === WasteTypeId.pszok
                && (segregationEntry.allowed.length > 0 || segregationEntry.disallowed.length > 0),
            )[0];
            return <PunktSelektywnejZbiorki pszoks={ pszoks } segregation={ pszokSegregation }/>;
        },
    },

    'label_on_packs': {
        Icon: BoxSigns,
        renderContent: () => <OznakowaniaNaOpakowaniach/>,
    },

    'bins_and_segregation': {
        Icon: ContainerTypes,
        renderContent: ({ segregations, bins }) => {
            const otherSegregations = segregations.filter(segregationEntry =>
                (segregationEntry.allowed.length > 0 || segregationEntry.disallowed.length > 0)
                && ![WasteTypeId.pszok, WasteTypeId.gabaryt].includes(segregationEntry.collectionPointCode),
            );
            return <RodzajeKontenerow segregations={ otherSegregations } bins={ bins }/>;
        },
    },

    'tip': {
        Icon: Curiosities,
        renderContent: ({tips}) => <Ciekawostki tips={tips}/>,
    },

    'electric_waste': {
        Icon: Electric,
        renderContent: () => <Elektroodpady/>,
    },

    'large_reception': {
        Icon: BigContainer,
        renderContent: ({ segregations }) => {
            const gabarytSegregation = segregations.filter(segregationEntry =>
                segregationEntry.collectionPointCode === WasteTypeId.gabaryt
                && (segregationEntry.allowed.length > 0 || segregationEntry.disallowed.length > 0),
            )[0];
            return <OdpadyWielkogabarytowe segregation={ gabarytSegregation }/>;
        },
    },

    'medicine': {
        Icon: Medicines,
        renderContent: () => <Lekarstwa/>,
    },
}
