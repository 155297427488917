import { fromPairs } from 'lodash';
import { useCallback, useState } from 'react';
import useMountedState from 'react-use/lib/useMountedState';
import { identity } from '../others';

const toObjectMapTransformer = responseData => fromPairs(Object.values(responseData).map(entity => [entity.id, entity]));

const useData = (
  apiCallFn,
  initialData,
  responseResolver = identity,
  responseTransformer = toObjectMapTransformer,
) => {
  const [state, setState] = useState({ data: initialData, error: null, pending: false });

  const isMounted = useMountedState();

  const fetch = useCallback(
    async (...args) => {
      setState(prevState => ({ data: prevState.data, error: null, pending: true }));
      try {
        const res = await apiCallFn(...args);
        if (isMounted()) {
          const responseData = responseResolver(res);
          const data = responseTransformer(responseData);
          setState({ data, error: null, pending: false });
        }
      } catch (error) {
        if (isMounted()) {
          console.error(error);
          setState(prevState => ({ data: prevState.data, error, pending: false }));
        }
      }
    },
    [apiCallFn, isMounted, responseResolver, responseTransformer],
  );


  const setOne = useCallback(
    (id, value) => {
      setState(prevState => ({
        ...prevState,
        data: {
          ...prevState.data,
          [id]: value,
        },
      }));
    },
    [],
  );

  const deleteOne = useCallback(
    (id) => {
      setState((prevState) => {
        // eslint-disable-next-line no-unused-vars
        const { [id]: deletedValue, ...restData } = prevState.data;
        return {
          ...prevState,
          data: restData,
        };
      });
    },
    [],
  );

  const setData = useCallback((data) => {
    setState(prevState => ({ ...prevState, data }));
  }, []);

  return { data: state.data, error: state.error, pending: state.pending, fetch, setData, setOne, deleteOne };
};

export default useData;
