import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import Button from '../../components/Button';
import FormAutocomplete from '../../components/forms/FormAutocomplete';
import Block from '../../components/generic/Block';
import Flex from '../../components/generic/Flex';
import Grid from '../../components/generic/Grid';
import Text from '../../components/generic/Text';
import PageHeader from '../../components/PageHeader';
import Panel from '../../components/Panel';
import { fontSize } from '../../constants/font';
import handleEnter from '../../utils/handleEnter';
import { px } from '../../utils/px';
import { su } from '../../utils/styledComponents';
import AddressBaner from './AddressBaner';
import AdministrationNoticeAddress from './components/AdministrationNoticeAddress';
import useUnitData from './useUnitData';

const AddressForm = ({ setAddress }) => {
  const unitData = useUnitData();
  const {
    towns, streets, streetAddresses, townAddresses,
    selectTown, selectStreet, selectAddress,
    selectedTown, selectedStreet, selectedAddress,
  } = unitData;

  const isCorrectAddress = (selectedTown && selectedAddress)
    || (selectedTown && selectedStreet && selectedAddress)
    || (selectedTown && selectedStreet && streetAddresses.data.length === 0);

  const onSubmit = useCallback(() => {
    setAddress({
      id: selectedAddress ? selectedAddress.value : selectedStreet.value,
      textAddress: `${ selectedTown.name }, ${ selectedStreet ? `${ selectedStreet.name } ` : '' }${ selectedAddress ? selectedAddress.name : '' }`,
    });
  }, [selectedAddress, selectedStreet, selectedTown, setAddress]);

  const generateLabelForTown = (name, gmina, powiat) => {
    return (
      <>
        { name }
        {
          powiat && gmina ?
            <div style={ { color: "#848b98" } }>
              powiat { powiat }, gmina { gmina }
            </div>
            : gmina ?
            <div style={ { color: "#848b98" } }>
              gmina { gmina }
            </div>
            : null
        }
      </>
    )
  };

  return (
    <div>
      <PageHeader>Harmonogram wywozu odpadów</PageHeader>
      <Panel padding={ [4, `${ su(6) } ${ su(16) }`] } marginBottom={ px(32) }>
        <Block marginBottom={ 2 }>
          <Text bold marginBottom={ 3 } fontSize={ fontSize.large }>Podaj adres wywozu:</Text>
          <FormAutocomplete
            label="Miejscowość"
            placeholder="np. Milicz"
            items={ towns.data.map(town => ({
              value: town.id,
              label: generateLabelForTown(town.name, town.gmina, town.powiat),
              name: town.name
            })) }
            loading={ towns.pending }
            selectedItem={ selectedTown }
            onChange={ selectTown }
            openMenuOnFocus
            marginBottom={ 2 }
          />
          <Grid flow={ [null, 'column'] } columns={ [null, '3fr 2fr'] } gap={ [2, 3] } marginBottom={ 3 }>
            <Block>
              <FormAutocomplete
                label="Ulica"
                placeholder="np. Zamkowa"
                items={ streets.data.map(street => ({ value: street.id, label: street.name, name: street.name })) }
                loading={ streets.pending }
                onChange={ selectStreet }
                selectedItem={ selectedStreet }
                disabled={ selectedTown === null || streets.data.length === 0 }
                openMenuOnFocus
              />
            </Block>
            <Block>
              <FormAutocomplete
                label="Numer domu"
                placeholder=""
                items={
                  selectedStreet ? (
                    streetAddresses.data.map(streetAddress => ({
                      value: streetAddress.id,
                      label: streetAddress.number,
                      name: streetAddress.number
                    }))
                  ) : (
                    townAddresses.data.map(townAddress => ({
                      value: townAddress.id,
                      label: townAddress.number,
                      name: townAddress.number
                    }))
                  ) }
                loading={ selectedStreet ? streetAddresses.pending : townAddresses.pending }
                onChange={ selectAddress }
                selectedItem={ selectedAddress }
                disabled={ selectedTown === null || (streetAddresses.data.length === 0 && townAddresses.data.length === 0) }
                openMenuOnFocus
              />
            </Block>
          </Grid>
          <Flex justifyContent="center">
            <Button
              disabled={ !isCorrectAddress }
              onClick={ onSubmit }
              onKeyDown={ handleEnter(onSubmit) }
            >
              Pokaż harmonogram
            </Button>
          </Flex>
        </Block>
      </Panel>
      <Block marginBottom={ px(32) }>
        <AddressBaner/>
      </Block>
      <AdministrationNoticeAddress marginBottom={ 2 }/>
    </div>
  );
};

AddressForm.propTypes = {
  setAddress: PropTypes.func.isRequired,
};

export default AddressForm;
