import PropTypes from 'prop-types';
import React from 'react';
import phone from '../../../assets/phone-call.svg';
import BlockImg from '../../../components/generic/BlockImg';
import Flex from '../../../components/generic/Flex';
import Text from '../../../components/generic/Text';
import color from '../../../constants/color';
import { px } from '../../../utils/px';

const Call = ({ telephone }) => (
  <Flex as="a" href={`tel:${telephone}`} alignItems="center">
    <Text marginRight={1}>Zadzwoń do punktu:</Text>
    <BlockImg src={phone} alt="Telefon" marginRight={1} width={px(14)} />
    <Text color={color.link}>{telephone}</Text>
  </Flex>
);

Call.propTypes = {
  telephone: PropTypes.string.isRequired,
};

export default Call;
