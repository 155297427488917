import React from 'react';
import styled from 'styled-components';
import appStore from '../../assets/app-strore-btn.svg';
import triangle from '../../assets/banner-triangle.svg';
import googlePlay from '../../assets/google-play-btn.svg';
import phone from '../../assets/phone.png';
import blisko from '../../assets/znak-blisko.png';
import blisko2x from '../../assets/znak-blisko@2x.png';
import blisko3x from '../../assets/znak-blisko@3x.png';
import Block from '../../components/generic/Block';
import BlockImg from '../../components/generic/BlockImg';
import Flex from '../../components/generic/Flex';
import FlexItem from '../../components/generic/FlexItem';
import Text from '../../components/generic/Text';
import Link from '../../components/Link';
import NoBreaker from '../../components/NoBreaker';
import color from '../../constants/color';
import { fontSize, fontWeight } from '../../constants/font';
import { px } from '../../utils/px';
import { pxMediaQueryEM } from '../../utils/pxMediaQueryEM';
import srcSet from '../../utils/srcSet';

const BreakingFlexItem = styled(FlexItem)`
  display: none;
  @media(min-width: ${pxMediaQueryEM(615)}) {
    display: block;
  }
`;

const FlexWithBg = styled(Flex)`
  background-color: ${color.bannerBg2};
  background-image: url(${triangle});
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
`;

const NewsletterBaner = () => (
  <FlexWithBg justifyContent="center">
    <Block paddingH={px(15)} maxWidth={px(640)}>
      <Flex>
        <BreakingFlexItem grow={0} width={px(75)} marginRight={px(15)} paddingTop={px(8)}>
          <BlockImg src={blisko} srcSet={srcSet(blisko2x, blisko3x)} width="100%" alt="logo Blisko" />
        </BreakingFlexItem>
        <BreakingFlexItem grow={0} width={px(180)} marginRight={px(15)}>
          <BlockImg src={phone} width="100%" alt="Ilustracja aplikacji Blisko na telefonie" />
        </BreakingFlexItem>
        <FlexItem equal paddingTop={px(14)}>
          <Text fontSize={fontSize.large} fontWeight={fontWeight.bold} color="#ffffff" lineHeight="1.33" marginBottom={px(15)}>
            Sprawdź harmonogram <NoBreaker>w telefonie</NoBreaker> <NoBreaker>z aplikacją</NoBreaker> BLISKO!
          </Text>
          <Text color="#ffffff" marginBottom={px(15)}>
            Pobierz bezpłatną aplikację i korzystaj <NoBreaker>z harmonogramu</NoBreaker>, gdziekolwiek jesteś.
          </Text>
          <Flex marginBottom={px(15)}>
            <Link external href="https://play.google.com/store/apps/details?id=sisms.groups_only">
              <BlockImg src={googlePlay} marginRight={1} width={px(135)} alt="Pobierz z Google Play" />
            </Link>
            <Link external href="https://apps.apple.com/pl/app/blisko/id821655959?l=pl">
              <BlockImg src={appStore} width={px(135)} alt="Pobierz w App Store" />
            </Link>
          </Flex>
        </FlexItem>
      </Flex>
    </Block>
  </FlexWithBg>
);

export default NewsletterBaner;
