import Downshift from 'downshift';
import * as PropTypes from 'prop-types';
import React from 'react';
import { PTspacing } from '../../constants/propTypes';
import ControllerButton from '../autocomplete/ControllerButton';
import Deselect from '../autocomplete/Deselect';
import Info from '../autocomplete/Info';
import Item from '../autocomplete/Item';
import ItemsList from '../autocomplete/ItemsList';
import Block from '../generic/Block';
import FormInput from './FormInput';
import FormLabel from './FormLabel';

const FormAutocomplete = ({
  placeholder, label, items, selectedItem, loading, onChange, onInputValueChange,
  disabled, openMenuOnFocus, marginBottom,
}) => (
  <Downshift
    selectedItem={selectedItem}
    onChange={onChange}
    itemToString={item => (item ? item.name : '')}
    onInputValueChange={onInputValueChange}
  >
    {({
      getRootProps,
      getInputProps,
      getItemProps,
      getLabelProps,
      getMenuProps,
      getToggleButtonProps,
      isOpen,
      inputValue,
      highlightedIndex,
      toggleMenu,
      clearSelection,
    }) => (
      <Block relative {...getRootProps()}>
        {label && <FormLabel {...getLabelProps()}>{label}</FormLabel>}
        <Block relative>
          <FormInput {...getInputProps({
            placeholder,
            marginBottom,
            active: isOpen,
            disabled,
            ...(openMenuOnFocus ? { onFocus: toggleMenu } : {}),
            onChange: ((e) => {
              if (e.target.value === '') {
                clearSelection();
              }
            }),
          })}
          />
          {selectedItem ? (
            <ControllerButton {...getToggleButtonProps()}>
              <Deselect onClick={clearSelection} />
            </ControllerButton>
          ) : null}
        </Block>
        <ItemsList {...getMenuProps({ isOpen })}>
          {(isOpen && items.length === 0)
            ? <Info>{loading ? 'Wczytywanie...' : 'Brak wyników'}</Info>
            : null
          }
          {(isOpen && items.length > 0)
            ? items
              .filter(item => !inputValue || item.name.toLowerCase().includes(inputValue.toLowerCase()))
              .map((item, index) => (
                <Item
                  {...getItemProps({
                    key: item.value,
                    item,
                    highlighted: highlightedIndex === index,
                    selected: selectedItem === item,
                  })}
                >
                  {item.label}
                </Item>
              ))
            : null}
        </ItemsList>
      </Block>
    )}
  </Downshift>
);
FormAutocomplete.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  selectedItem: PropTypes.object,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onInputValueChange: PropTypes.func,
  disabled: PropTypes.bool,
  openMenuOnFocus: PropTypes.bool,
  marginBottom: PTspacing,
};
export default FormAutocomplete;
