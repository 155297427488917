import React, { Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import Block from '../components/generic/Block';
import Flex from '../components/generic/Flex';
import Spinner from '../components/Spinner';
import { View } from '../constants/enums/View';
import { useNavigation } from '../containers/NavigationProvider';
import { useUrlParams } from '../containers/UrlParamsProvider';
import { px } from '../utils/px';
import Footer from './Footer';
import GdzieWyrzucic from './GdzieWyrzucic/GdzieWyrzucic';
import MojHarmonogram from './MojHarmonogram/MojHarmonogram';
import Navigation from './Navigation/Navigation';
import WartoWiedziec from './WartoWiedziec/WartoWiedziec';
import ZoomControls from './ZoomControls';
import DokumentyDlaMieszkanca from "./DokumentyDlaMieszkanca/DokumentyDlaMieszkanca";

// suspensing these two because of MapBox use, which is a huge dependency
const ZnajdzNaMapieLazy = React.lazy(() => import('./ZnajdzNaMapie/ZnajdzNaMapie'));
const SuspensedZnajdzNaMapie = () => (
  <Suspense fallback={<Flex justifyContent="center" marginBottom={5}><Spinner width={12} /></Flex>}>
    <ZnajdzNaMapieLazy />
  </Suspense>
);

const ZglosLazy = React.lazy(() => import('./Zglos/Zglos'));
const SuspensedZglos = () => (
  <Suspense fallback={<Flex justifyContent="center" marginBottom={5}><Spinner width={12} /></Flex>}>
    <ZglosLazy />
  </Suspense>
);

const componentForView = Object.freeze({
  [View.mojHarmonogram]: MojHarmonogram,
  [View.gdzieWyrzucic]: GdzieWyrzucic,
  [View.wartoWiedziec]: WartoWiedziec,
  [View.znajdzNaMapie]: SuspensedZnajdzNaMapie,
  [View.zglos]: SuspensedZglos,
  [View.dokumentyDlaMieszkanca]: DokumentyDlaMieszkanca
});

const Main = () => {
  const { theme, hideMenu, hideZoom } = useUrlParams();
  const { activeView } = useNavigation();
  const Component = componentForView[activeView];
  return (
    <ThemeProvider theme={theme}>
      <Block width="100%" height="100%" style={{ overflow: 'auto' }}>
        <Block padding={[2, 3]} maxWidth={px(960)} margin="0 auto">
          {!hideZoom && <ZoomControls />}
          {!hideMenu && <Navigation />}
          <main>
            <Component />
          </main>
          <Footer />
        </Block>
      </Block>
    </ThemeProvider>
  );
};

Main.propTypes = {};

export default Main;
