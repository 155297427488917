import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import Divider from '../../../../components/Divider';
import Block from '../../../../components/generic/Block';
import SectionHeader from '../../../../components/SectionHeader';
import { LS_COMPANY_RATINGS } from '../../../../constants/localStorage';
import { useUrlParams } from '../../../../containers/UrlParamsProvider';
import { companies } from '../../../../utils/api';
import useData from '../../../../utils/hooks/useData';
import { nestedResponseResolver } from '../../../../utils/hooks/useListData';
import Company from './Company';

const Companies = ({ unitId }) => {
  const { ownerId } = useUrlParams();
  const { data, fetch, setOne } = useData(companies.list, {}, nestedResponseResolver);
  const [ratings, setRatings] = useLocalStorage(LS_COMPANY_RATINGS, {});

  useEffect(() => {
    fetch(ownerId, unitId);
  }, [fetch, ownerId, unitId]);

  const rate = useCallback(async (companyId, rating) => {
    const original = data[companyId];
    try {
      //optimistic update
      setOne(companyId, {
        ...original,
        opinionsCount: original.opinionsCount + 1,
      });
      setRatings(prevRatings => ({
        ...prevRatings,
        [companyId]: rating,
      }));
      await companies.rate(ownerId, companyId, rating);
    } catch (e) {
      //revert
      setOne(companyId, original);
      setRatings(prevRatings => omit(prevRatings, [companyId]));
      throw e;
    }
  }, [data, ownerId, setOne, setRatings]);

  if (Object.keys(data).length === 0) {
    return null;
  }

  return (
    <Block marginBottom={9} as="section">
      <SectionHeader>W Twojej lokalizacji obsługą odpadów zajmują się:</SectionHeader>
      <Divider />
      {Object.values(data).map((company) => {
        const companyWithRating = { ...company, rating: ratings[company.id] };
        return (
          <Company
            key={company.id}
            company={companyWithRating}
            rate={rating => rate(company.id, rating)}
          />
        );
      })}
    </Block>
  );
};
Companies.propTypes = {
  unitId: PropTypes.string.isRequired,
};

export default Companies;
