import React from 'react';
import styled from 'styled-components';
import Block from '../../../components/generic/Block';
import Panel from '../../../components/Panel';
import PokazNaMapie from '../../../components/PokazNaMapie';
import { fontWeight } from '../../../constants/font';
import hexToRgb from '../../../utils/hexToRgb';
import { px } from '../../../utils/px';
import { MediaObject } from '../components/MediaObject';

const RoundContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${px(20)};
  height: ${px(20)};
  color: ${props => props.theme.colorAccent};
  background-color: rgba(${props => hexToRgb(props.theme.colorAccent)}, 0.2);
  border-radius: 50%;
  
  // Fine tuning to make it look centered. Probably digits are not drawn in the center of their font box
  padding-bottom: ${px(1)}; 
  padding-left: ${px(1)};
`;
const TextWithNumber = styled.div`
  font-weight: ${fontWeight.bold};
  color: ${props => props.theme.colorAccent};
`;

const Ciekawostki = ({tips}) => (
  <Panel>
    <ul>
      {tips.map(({ subject, description, mapFilters }, index) => (
        <MediaObject
          as="li"
          key={index}
          PicturePart={
            <RoundContainer>
              <TextWithNumber>{index + 1}</TextWithNumber>
            </RoundContainer>
          }
          title={subject}
          description={
            <>
              <Block marginBottom={2}>{description}</Block>
              {mapFilters && <PokazNaMapie wasteTypes={mapFilters} />}
            </>
          }
        />
      ))}
    </ul>
  </Panel>
);

export default Ciekawostki;
