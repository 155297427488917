import PropTypes from 'prop-types';
import React from 'react';
import Divider from '../../../../components/Divider';
import Block from '../../../../components/generic/Block';
import Grid from '../../../../components/generic/Grid';
import Text from '../../../../components/generic/Text';
import Link from '../../../../components/Link';
import Stars from './Stars';

const Company = ({ company, rate }) => (
  <>
    <Grid rows="auto" columns={['1fr', 'minmax(0, 1fr) minmax(0, 1fr)']} gap={[2, 3]}>
      <Block padding={3}>
        <Text bold as="h3" marginBottom={2}>{company.name}</Text>
        <Block marginBottom={2}>
          {company.address && <Text style={{ whiteSpace: 'pre-wrap' }}>{company.address}</Text>}
          {company.phone && <Text style={{ wordBreak: 'break-word' }}>tel.: {company.phone}</Text>}
          {company.email && (
            <Block>e-mail: <Link inline style={{ wordBreak: 'break-word' }} external href={`mailto:${company.email}`}>{company.email}</Link></Block>
          )}
          {company.url && (
            <Block>www: <Link inline style={{ wordBreak: 'break-word' }} external href={`${company.url}`}>{company.url}</Link></Block>
          )}

        </Block>
        {/*<Text>Zbierane odpady:</Text>*/}
      </Block>
      <Block padding={3}>
        <Stars company={company} rate={rate} />
      </Block>
    </Grid>
    <Divider />
  </>
);

Company.propTypes = {
  company: PropTypes.object.isRequired,
  rate: PropTypes.func.isRequired,
};
export default Company;
