import styled from 'styled-components';
import color from '../constants/color';
import { px } from '../utils/px';
import Block from './generic/Block';

const Divider = styled(Block)`
  border-bottom: ${px(1)} solid ${color.divider};   
`;
Divider.propTypes = Block.propTypes;

export default Divider;
