import PropTypes from 'prop-types';
import React from 'react';
import sent from '../assets/icon_newsletter--success.svg';
import Block from './generic/Block';
import BlockImg from './generic/BlockImg';
import Grid from './generic/Grid';
import Text from './generic/Text';
import color from '../constants/color';
import { fontWeight } from '../constants/font';
import { px } from '../utils/px';

const FormSent = ({ reset, children }) => (
  <Grid columns={`${px(27)} auto`} rows="auto auto" gap={`${px(10)} ${px(5)}`} alignItems="center">
    <BlockImg src={sent} width="100%" alt="zielona koperta" />
    <Block paddingTop={px(6)}>
      <Text fontWeight={fontWeight.bold} color={color.formSent}>Formularz przesłany pomyślnie</Text>
    </Block>
    <div />
    <div>
      {children}
      <Text onClick={reset} color={color.link}>Zamów kolejne komunikaty</Text>
    </div>
  </Grid>
);

FormSent.propTypes = {
  reset: PropTypes.func.isRequired,
};

export default FormSent;
