import PropTypes from 'prop-types';
import React from 'react';
import Block from '../../components/generic/Block';
import PageHeader from '../../components/PageHeader';
import { useUrlParams } from '../../containers/UrlParamsProvider';
import { px } from '../../utils/px';
import Companies from './components/Companies/Companies';
import Links from './components/Links';
import Newsletter from './components/Newsletter/Newsletter';
import Timetable from './components/Timetable/Timetable';
import NewsletterBaner from './NewsletterBaner';

const AddressResult = ({ address, onChangeAddress }) => {
  const { hideMenu } = useUrlParams();
  const { id, textAddress } = address;
  return (
    <>
      <PageHeader>Harmonogram wywozu odpadów</PageHeader>
      <Timetable unitId={id} textAddress={textAddress} onChangeAddress={onChangeAddress} />
      {!hideMenu && <Links />}
      <Companies unitId={id} />
      <Newsletter unitId={id} />
      <Block marginV={px(32)}>
        <NewsletterBaner />
      </Block>
    </>
  );
};

AddressResult.propTypes = {
  address: PropTypes.shape({
    id: PropTypes.string.isRequired,
    textAddress: PropTypes.string.isRequired,
  }),
  onChangeAddress: PropTypes.func.isRequired,
};

export default AddressResult;
