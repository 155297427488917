import styled from 'styled-components';
import { ReactComponent as X } from '../../assets/x-dark.svg';
import color from '../../constants/color';
import { px } from '../../utils/px';

const Deselect = styled(X)`
  color: ${color.navItem};
  width: ${px(11)};
`;

export default Deselect;
