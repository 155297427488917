import React from 'react';
import Block from '../../../components/generic/Block';
import Text from '../../../components/generic/Text';
import Link from '../../../components/Link';
import { fontSize } from '../../../constants/font';
import { PTspacing } from '../../../constants/propTypes';
import { useOwnerInfo } from '../../../containers/OwnerInfoProvider';
import { backendEnvURL } from '../../../utils/api';

const AdministrationNoticeHarmonogram = (props) => {
  const { name, address, nip, regon, politykaPrywatnosciURL } = useOwnerInfo();

  return (
    <Text fontSize={fontSize.semiSmall} {...props}>
      <Block marginBottom={1}>
        Administratorem danych osobowych jest: {name}, {address}, NIP: {nip}; REGON: {regon}.
        Dokumenty zawsze dostępne <Link external inline href={politykaPrywatnosciURL}>tutaj</Link>.
      </Block>
      <Link external href={`${backendEnvURL}/regulations/manual`}>Jak zrezygnować?</Link>
    </Text>
  );
};

AdministrationNoticeHarmonogram.propTypes = {
  marginBottom: PTspacing,
};

export default AdministrationNoticeHarmonogram;
