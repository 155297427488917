import queryString from 'query-string';
import React, { useContext, useMemo } from 'react';
import color from '../constants/color';
import { View } from '../constants/enums/View';
import { PTchildren } from '../constants/propTypes';

const UrlParamsContext = React.createContext();

export const UrlParamsProvider = ({ children }) => {
  const {
    ownerId,
    hideMenu = false,
    hideZoom = false,
    initialView: providedInitialView,
    colorAccent = '#6262fa',
    colorButtonBg = '#6262fa',
    colorButtonBgHover = '#5252c6',
    colorInputActive = color.link,
    dimensionInputActiveBorder = 2,
  } = queryString.parse(window.location.search, { parseBooleans: true });

  const initialViewIsValid = Object.values(View).includes(providedInitialView);
  if (providedInitialView && !initialViewIsValid) {
    console.warn(
      `Została podana niepoprawna wartość parametru "initialView". Użyto domyślnej wartości "${View.mojHarmonogram}".
Dozwolone wartości to: [${Object.values(View).map(v => `"${v}"`).join(', ')}]`,
    );
  }
  const initialView = (providedInitialView && initialViewIsValid) ? providedInitialView : View.mojHarmonogram;

  const theme = {
    colorAccent,
    colorButtonBg,
    colorButtonBgHover,
    colorInputActive,
    dimensionInputActiveBorder,
  };

  const value = useMemo(() => ({
    ownerId,
    hideMenu,
    hideZoom,
    initialView,
    theme,
  }), [ownerId, hideMenu, hideZoom, initialView, theme]);
  return (
    <UrlParamsContext.Provider value={value}>
      {children}
    </UrlParamsContext.Provider>
  );
};

UrlParamsProvider.propTypes = {
  children: PTchildren,
};

export const useUrlParams = () => {
  const context = useContext(UrlParamsContext);
  if (context === undefined) {
    throw new Error('useUrlParams must be used  together with UrlParamsProvider');
  }
  return context;
};
