import { useMemo } from 'react';
import useMedia from 'react-use/lib/useMedia';
import { DEVICE, MEDIA } from '../../constants/breakpoints';

const useDevice = () => {
  const isMediaTablet = useMedia(MEDIA.tablet);
  const isMediaDesktop = useMedia(MEDIA.desktop);

  return useMemo(() => {
    if (isMediaDesktop) {
      return DEVICE.desktop;
    }
    if (isMediaTablet) {
      return DEVICE.tablet;
    }
    return DEVICE.mobile;
  }, [isMediaDesktop, isMediaTablet]);
};

useDevice.propTypes = {};

export default useDevice;
