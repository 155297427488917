import moment from 'moment';
import 'moment/locale/pl';
import React from 'react';
import { MapFiltersProvider } from './containers/MapFiltersProvider';
import { NavigationProvider } from './containers/NavigationProvider';
import { OwnerInfoProvider } from './containers/OwnerInfoProvider';
import { UrlParamsProvider } from './containers/UrlParamsProvider';
import { ZoomProvider } from './containers/ZoomProvider';
import GlobalStyles from './views/GlobalStyles';
import Main from './views/Main';

moment.locale('pl');

const Compose = ({ components = [], children }) => (
  components.reduceRight((acc, Comp) => <Comp>{acc}</Comp>, children)
);

const App = () => (
  <Compose components={[UrlParamsProvider, ZoomProvider, NavigationProvider, OwnerInfoProvider, MapFiltersProvider]}>
    <GlobalStyles />
    <Main />
  </Compose>
);

export default App;
